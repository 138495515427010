import React, { useContext, useState } from 'react';
import { TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tooltip } from '@mui/material';
import EditorContext from '../EditorContext/EditorContext';
import Iconify from '../Iconify/Iconify';
import CodeEditor from '@uiw/react-textarea-code-editor';
import './Controls.scss';

function ControlTimeline(props: any) {
  const { timelines, setTimelines, timeline, setTimeline, animationCurrent, setAnimationCurrent, openModalTimelines, setOpenModalTimelines, cssSelector, setCssSelector } = useContext(EditorContext);
  const [startEnd, setStartEnd] = useState<string>('Start');
  const handleChange = (e: any, controlType: string) => {
    const timelinesNew = timelines.map((item: any) => {
      if (item.id === timeline.id) {
        const controlId = controlType === 'select' ? e.target.name : e.target.attributes?.id?.value;

        switch (controlType) {
          case 'checkbox':
            item[controlId] = !(item[controlId]) ? true : false;
            break;

          default:
            if (controlId === 'triggerSelector') {
              try {
                document.querySelector(e.target.value);
                e.target.closest('.controls__field-group').classList.remove('error');
              } catch (err) {
                e.target.closest('.controls__field-group').classList.add('error');
              }
              if (e.target.value === '') {
                e.target.closest('.controls__field-group').classList.remove('error');
              }
            }
            item[controlId] = e.target.value;
            break;
        }
				setTimeline(item);
        return item;
      }

			return item;
    });

    setTimelines(timelinesNew);
  };

  const toggleActions = ["play", "pause", "resume", "reset", "restart", "complete", "reverse", "none"];
  const togglePlaces = [
    {
      value:"onEnter",
      label:"On Enter",
      default: 'play'
    },
    {
      value:"onLeave",
      label:"On Leave",
      default: 'none'
    },
    {
      value:"onEnterBack",
      label:"On Enter Back",
      default: 'none'
    },
    {
      value:"onLeaveBack",
      label:"On Leave Back",
      default: 'none'
    }
  ];

  const handleChangeStartEnd = (e: any) => {
    setStartEnd(e.target.value);
  };

  const startEndArray = ['Start', 'End'];

  return (
    <>
      <div className="controls">
        {timeline.id && (
          <>
            <div
              className="controls__header"
              onClick={(e) => {
                const tar = e.target as HTMLElement;
                tar.closest('.controls')?.classList.toggle('controls--collapsed');
              }}
            >
              <div className="controls__header-label">Timeline</div>
              <div className="controls__header-icon">
                <Iconify icon="material-symbols:keyboard-arrow-down" />
              </div>
            </div>

            <div className="controls__body">
              <div className="controls__field-group controls__field-group--label-block">
                <div className="controls__button" onClick={() => setOpenModalTimelines(true)}>Select, import, create the timeline</div>
              </div>

              <div className="controls__field-group controls__field-group--label-block">
                <label htmlFor="name">Name</label>
                <TextField
                  id="name"
                  value={timeline.name ? timeline.name : ''}
                  fullWidth
                  onChange={(e) => {
                    handleChange(e, 'text');
                  }}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="trigger">Trigger on</label>
                <Select
                  id="trigger"
                  name='trigger'
                  value={timeline.trigger ? timeline.trigger : 'page'}
                  onChange={(e) => {
                    handleChange(e, 'select');
                  }}
                  IconComponent={() => (
                    <div className="control-select-arrow">
                      <Iconify icon="material-symbols:keyboard-arrow-down" />
                    </div>
                  )}
                >
                  <MenuItem value="page" style={{fontSize: '12px'}}>Page Load</MenuItem>
                  <MenuItem value="pageExit" style={{fontSize: '12px'}}>Page Exit</MenuItem>
                  <MenuItem value="scroll" style={{fontSize: '12px'}}>Scroll</MenuItem>
                  <MenuItem value="click" style={{fontSize: '12px'}}>Click</MenuItem>
                  <MenuItem value="hover" style={{fontSize: '12px'}}>Hover</MenuItem>
                </Select>
              </div>

              {timeline.trigger === 'page' && (
                <div className="controls__field-group">
                  <label htmlFor="pageStart">Start When</label>
                  <Select
                    id="pageStart"
                    name='pageStart'
                    value={timeline.pageStart ? timeline.pageStart : 'htmlLoaded'}
                    onChange={(e) => {
                      handleChange(e, 'select');
                    }}
                    IconComponent={() => (
                      <div className="control-select-arrow">
                        <Iconify icon="material-symbols:keyboard-arrow-down" />
                      </div>
                    )}
                  >
                    <MenuItem value="htmlLoaded" style={{fontSize: '12px'}}>HTML loaded</MenuItem>
                    <MenuItem value="contentLoaded" style={{fontSize: '12px'}}>Content loaded</MenuItem>
                  </Select>
                </div>
              )}

              {['click', 'hover'].includes(timeline.trigger) && (
                <div className="controls__field-group controls__field-group--selector controls__field-group--label-block">
                  <label className='label-flex' htmlFor="triggerSelector">
                    <span>Trigger element selector</span>
                    <div
                      className={
                        cssSelector.enable && cssSelector.controlId === 'triggerSelector' && cssSelector.type === 'timeline'
                          ? 'selector-target active'
                          : 'selector-target'
                      }
                      onClick={() => {
                        const enableCssSelector = !cssSelector.enable;
                        setCssSelector({ enable: enableCssSelector, controlId: 'triggerSelector', type: 'timeline', multiple: false });
                      }}
                    >
                      <Iconify icon="fluent:resize-small-16-regular" />
                    </div>
                  </label>
                  <TextField
                    value={timeline.triggerSelector}
                    id='triggerSelector'
                    onChange={(e) => {
                      handleChange(e, 'text');
                    }}
                    placeholder='Enter selector'
                  />
                  <div className='invalid-message'>Invalid Selector</div>
                </div>
              )}

              {timeline.trigger === 'scroll' && (
                <>
                  <div className="controls__field-group controls__field-group--label-block">
                    <RadioGroup
                      row
                      className='radio-group'
                      id="startEnd"
                      value={startEnd}
                      onChange={handleChangeStartEnd}
                    >
                      <FormControlLabel value="Start" control={<Radio id="startEnd" />} label="Start when" />
                      <FormControlLabel value="End" control={<Radio id="startEnd" />} label="End when" />
                    </RadioGroup>
                  </div>

                  {startEndArray.map((item: string) => (
                    <div key={item} className={item !== startEnd ? 'hidden' : ''}>

                      {startEnd === 'Start' && (
                        <div className="controls__field-group controls__field-group--selector controls__field-group--label-block">
                          <label className='label-flex' htmlFor="triggerSelector">
                            <span>Trigger element selector</span>
                            <div
                              className={
                                cssSelector.enable && cssSelector.controlId === 'triggerSelector' && cssSelector.type === 'timeline'
                                  ? 'selector-target active'
                                  : 'selector-target'
                              }
                              onClick={() => {
                                const enableCssSelector = !cssSelector.enable;
                                setCssSelector({ enable: enableCssSelector, controlId: 'triggerSelector', type: 'timeline', multiple: false });
                              }}
                            >
                              <Iconify icon="fluent:resize-small-16-regular" />
                            </div>
                          </label>
                          <TextField
                            value={timeline.triggerSelector}
                            id='triggerSelector'
                            onChange={(e) => {
                              handleChange(e, 'text');
                            }}
                            placeholder='Enter selector'
                          />
                          <div className='invalid-message'>Invalid Selector</div>
                        </div>
                      )}

                      {startEnd === 'End' && (
                        <div className="controls__field-group controls__field-group--selector controls__field-group--label-block">
                          <label className='label-flex' htmlFor="endTriggerSelector">
                            <span>End trigger element selector
                            <Tooltip title="You don't need to define an End Trigger unless it's DIFFERENT than the trigger element because that's the default" arrow placement="top">
                              <div className='control-description' style={{display: "inline"}}><Iconify icon="ph:info" /></div>
                            </Tooltip>
                            </span>
                            
                            <div
                              className={
                                cssSelector.enable && cssSelector.controlId === 'endTriggerSelector' && cssSelector.type === 'timeline'
                                  ? 'selector-target active'
                                  : 'selector-target'
                              }
                              onClick={() => {
                                const enableCssSelector = !cssSelector.enable;
                                setCssSelector({ enable: enableCssSelector, controlId: 'endTriggerSelector', type: 'timeline', multiple: false });
                              }}
                            >
                              <Iconify icon="fluent:resize-small-16-regular" />
                            </div>
                          </label>
                          <TextField
                            value={timeline.endTriggerSelector}
                            id='endTriggerSelector'
                            onChange={(e) => {
                              handleChange(e, 'text');
                            }}
                            placeholder='Enter selector'
                          />
                          <div className='invalid-message'>Invalid Selector</div>
                        </div>
                      )}

                      <div className="controls__field-group">
                        <label htmlFor={'scrollTriggerStartEndTriggerElement' + item}>Trigger Element</label>
                        <Select
                          id={'scrollTriggerStartEndTriggerElement' + item}
                          name={'scrollTriggerStartEndTriggerElement' + item}
                          value={timeline['scrollTriggerStartEndTriggerElement' + item] ? timeline['scrollTriggerStartEndTriggerElement' + item] : (item === 'Start' ? 'top' : 'bottom')}
                          onChange={(e) => {
                            handleChange(e, 'select');
                          }}
                          IconComponent={() => (
                            <div className="control-select-arrow">
                              <Iconify icon="material-symbols:keyboard-arrow-down" />
                            </div>
                          )}
                        >
                          <MenuItem value="top" style={{fontSize: '12px'}}>top</MenuItem>
                          <MenuItem value="center" style={{fontSize: '12px'}}>center</MenuItem>
                          <MenuItem value="bottom" style={{fontSize: '12px'}}>bottom</MenuItem>
                        </Select>
                      </div>

                      <div className="controls__field-group">
                        <label htmlFor={'scrollTriggerStartEndTriggerElementOffset' + item}>
                          Offset
                          <Tooltip title="e.g 15%, -100px" arrow placement="top">
                            <div className='control-description'><Iconify icon="ph:info" /></div>
                          </Tooltip>
                        </label>
                        <TextField
                          id={'scrollTriggerStartEndTriggerElementOffset' + item}
                          value={timeline['scrollTriggerStartEndTriggerElementOffset' + item] ? timeline['scrollTriggerStartEndTriggerElementOffset' + item] : ''}
                          fullWidth
                          onChange={(e) => {
                            handleChange(e, 'text');
                          }}
                        />
                      </div>

                      <div className="controls__field-group">
                        <label htmlFor={'scrollTriggerStartEndViewport' + item}>Viewport</label>
                        <Select
                          id={'scrollTriggerStartEndViewport' + item}
                          name={'scrollTriggerStartEndViewport' + item}
                          value={timeline['scrollTriggerStartEndViewport' + item] ? timeline['scrollTriggerStartEndViewport' + item] : (item === 'Start' ? 'bottom' : 'top')}
                          onChange={(e) => {
                            handleChange(e, 'select');
                          }}
                          IconComponent={() => (
                            <div className="control-select-arrow">
                              <Iconify icon="material-symbols:keyboard-arrow-down" />
                            </div>
                          )}
                        >
                          <MenuItem value="top" style={{fontSize: '12px'}}>top</MenuItem>
                          <MenuItem value="center" style={{fontSize: '12px'}}>center</MenuItem>
                          <MenuItem value="bottom" style={{fontSize: '12px'}}>bottom</MenuItem>
                        </Select>
                      </div>

                      <div className="controls__field-group">
                        <label htmlFor={'scrollTriggerStartEndViewportOffset' + item}>
                          Offset
                          <Tooltip title="e.g 15%, -100px" arrow placement="top">
                            <div className='control-description'><Iconify icon="ph:info" /></div>
                          </Tooltip>
                        </label>
                        
                        <TextField
                          id={'scrollTriggerStartEndViewportOffset' + item}
                          value={timeline['scrollTriggerStartEndViewportOffset' + item] ? timeline['scrollTriggerStartEndViewportOffset' + item] : ''}
                          fullWidth
                          onChange={(e) => {
                            handleChange(e, 'text');
                          }}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="separator"></div>

                  <div className="controls__field-group">
                    <label htmlFor="scrollTriggerToggleActions">
                      <span>Toggle Actions</span>
                    </label>
                    <Switch
                      checked={timeline.scrollTriggerToggleActions ? true : false}
                      onChange={(e) => {
                        handleChange(e, 'checkbox');
                      }}
                      id='scrollTriggerToggleActions'
                    />
                  </div>

                  {timeline.scrollTriggerToggleActions && (
                    <>
                      {togglePlaces.map((item, index) => (
                        <div className="controls__field-group" key={index}>
                          <label htmlFor={`scrollTriggerToggleActions${item.value}`}>{item.label}</label>
                          <Select
                            id={`scrollTriggerToggleActions${item.value}`}
                            name={`scrollTriggerToggleActions${item.value}`}
                            value={timeline[`scrollTriggerToggleActions${item.value}`] ? timeline[`scrollTriggerToggleActions${item.value}`] : item.default}
                            onChange={(e) => {
                              handleChange(e, 'select');
                            }}
                            IconComponent={() => (
                              <div className="control-select-arrow">
                                <Iconify icon="material-symbols:keyboard-arrow-down" />
                              </div>
                            )}
                          >
                            {toggleActions.map((item, index) => (
                              <MenuItem value={item} style={{fontSize: '12px'}} key={index}>{item}</MenuItem>
                            ))}
                          </Select>
                        </div>
                      ))}
                    </>
                  )}

                  <div className="controls__field-group">
                    <label htmlFor="scrollTriggerToggleClass">Toggle Class</label>
                    <TextField
                      id="scrollTriggerToggleClass"
                      value={timeline.scrollTriggerToggleClass ? timeline.scrollTriggerToggleClass : ''}
                      fullWidth
                      placeholder='e.g active'
                      onChange={(e) => {
                        handleChange(e, 'text');
                      }}
                    />
                  </div>

                  <div className="controls__field-group">
                    <label htmlFor="scrollTriggerScrub">
                      <span>Scrub</span>
                    </label>
                    <Switch
                      checked={timeline.scrollTriggerScrub ? true : false}
                      onChange={(e) => {
                        handleChange(e, 'checkbox');
                      }}
                      id='scrollTriggerScrub'
                    />
                  </div>

                  <div className="controls__field-group">
                    <label htmlFor="scrollTriggerPin">
                      <span>Pin element</span>
                    </label>
                    <Switch
                      checked={timeline.scrollTriggerPin ? true : false}
                      onChange={(e) => {
                        handleChange(e, 'checkbox');
                      }}
                      id='scrollTriggerPin'
                    />
                  </div>
                  
                  {timeline.scrollTriggerPin && (
                    <div className="controls__field-group controls__field-group--selector controls__field-group--label-block">
                      <label className='label-flex' htmlFor="scrollTriggerPinSelector">
                        <span>Pin element selector</span>
                        <div
                          className={
                            cssSelector.enable && cssSelector.controlId === 'scrollTriggerPinSelector' && cssSelector.type === 'timeline'
                              ? 'selector-target active'
                              : 'selector-target'
                          }
                          onClick={() => {
                            const enableCssSelector = !cssSelector.enable;
                            setCssSelector({ enable: enableCssSelector, controlId: 'scrollTriggerPinSelector', type: 'timeline', multiple: false });
                          }}
                        >
                          <Iconify icon="fluent:resize-small-16-regular" />
                        </div>
                      </label>
                      <TextField
                        value={timeline.scrollTriggerPinSelector}
                        id='scrollTriggerPinSelector'
                        onChange={(e) => {
                          handleChange(e, 'text');
                        }}
                        placeholder='Enter selector'
                      />
                    </div>
                  )}

                  <div className="controls__field-group controls__field-group--label-block">
                    <label htmlFor="custom">Custom scrollTrigger options</label>
                    <div className='code-wrapper'>
                      <CodeEditor
                        id="scrollTriggerCustom"
                        value={timeline.scrollTriggerCustom ? timeline.scrollTriggerCustom : ''}
                        language="js"
                        placeholder='property: "value",
property2: "value2",'
                        onChange={(e) => {
                          handleChange(e, 'text');
                        }}
                        data-color-mode="dark"
                      />
                    </div>
                  </div>

                </>
              )}

              {timeline.trigger === 'click' && (
                <>
                  <div className="controls__field-group">
                    <label htmlFor="secondClickReverse">
                      <span>Reverse on 2nd click</span>
                    </label>
                    <Switch
                      checked={timeline.secondClickReverse ? true : false}
                      onChange={(e) => {
                        handleChange(e, 'checkbox');
                      }}
                      id='secondClickReverse'
                    />
                  </div>

                  <div className="controls__field-group">
                    <label htmlFor="secondClickRestart">
                      <span>Restart on 2nd click</span>
                    </label>
                    <Switch
                      checked={timeline.secondClickRestart ? true : false}
                      onChange={(e) => {
                        handleChange(e, 'checkbox');
                      }}
                      id='secondClickRestart'
                    />
                  </div>
                </>
              )}

              {timeline.trigger === 'hover' && (
                <>
                  <div className="controls__field-group">
                    <label htmlFor="mouseLeaveReverse">
                      <span>Reverse on mouse leave</span>
                    </label>
                    <Switch
                      checked={timeline.mouseLeaveReverse ? true : false}
                      onChange={(e) => {
                        handleChange(e, 'checkbox');
                      }}
                      id='mouseLeaveReverse'
                    />
                  </div>

                  <div className="controls__field-group">
                    <label htmlFor="mouseLeaveRestart">
                      <span>Restart on mouse leave</span>
                    </label>
                    <Switch
                      checked={timeline.mouseLeaveRestart ? true : false}
                      onChange={(e) => {
                        handleChange(e, 'checkbox');
                      }}
                      id='mouseLeaveRestart'
                    />
                  </div>
                </>
              )}

            </div>
          </>
        )}
      </div>

      <div className="controls controls--collapsed">
        {timeline.id && (
          <>
            <div
              className="controls__header"
              onClick={(e) => {
                const tar = e.target as HTMLElement;
                tar.closest('.controls')?.classList.toggle('controls--collapsed');
              }}
            >
              <div className="controls__header-label">Other Options</div>
              <div className="controls__header-icon">
                <Iconify icon="material-symbols:keyboard-arrow-down" />
              </div>
            </div>

            <div className="controls__body">
              
              <div className="controls__field-group">
                <label htmlFor="mobile">
                  <span>Mobile</span>
                </label>
                <Switch
                  checked={timeline.mobile ? true : false}
                  onChange={(e) => {
                    handleChange(e, 'checkbox');
                  }}
                  id='mobile'
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="tablet">
                  <span>Tablet</span>
                </label>
                <Switch
                  checked={timeline.tablet ? true : false}
                  onChange={(e) => {
                    handleChange(e, 'checkbox');
                  }}
                  id='tablet'
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="desktop">
                  <span>Desktop</span>
                </label>
                <Switch
                  checked={timeline.desktop ? true : false}
                  onChange={(e) => {
                    handleChange(e, 'checkbox');
                  }}
                  id='desktop'
                />
              </div>

            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ControlTimeline;
