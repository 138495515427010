import React, { useContext } from 'react';
import { Rnd } from 'react-rnd';
import EditorContext from '../EditorContext/EditorContext';

function Animation(props: any) {
  const animation = props.animation;
  const resizeDirections = {
    bottom: false,
    bottomLeft: false,
    bottomRight: false,
    left: true,
    right: true,
    top: false,
    topLeft: false,
    topRight: false,
  };

  const { timelines, setTimelines, timeline, setTimeline, animationCurrent, setAnimationCurrent, cssSelector, setCssSelector, editorTab, setEditorTab } = useContext(EditorContext);

  return (
    <div className={animation.id === animationCurrent.id ? 'animation active' : 'animation'}>
      <Rnd
        dragAxis="x"
        bounds="parent"
        enableResizing={resizeDirections}
        size={{ width: animation.duration / 4 * 1000, height: 29 }}
        position={{ x: animation.delay / 4 * 1000, y: 0 }}
        resizeGrid={[25, 29]}
        dragGrid={[25, 25]}
        onDragStop={(e, d) => {
          const timelinesNew = timelines.map((item: any) => {
            if (item.id === timeline.id) {
              let animationsNew = item.animations.map((animationItem: any) => {
                if (animationItem.id === animation.id) {
                  animationItem.delay = Math.round(d.x * 0.004 * 100) / 100;
                }
                setAnimationCurrent(animation);
                setEditorTab('animation');
                return animationItem;
              });
              return {
                ...item,
                animations: animationsNew,
              };
            } else {
              return item;
            }
          });
          setTimelines(timelinesNew);
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          const timelinesNew = timelines.map((item: any) => {
            if (item.id === timeline.id) {
              let animationsNew = item.animations.map((animationItem: any) => {
                if (animationItem.id === animation.id) {
                  animationItem.delay = Math.round(position.x * 0.004 * 100) / 100;
                  animationItem.duration = Math.round(ref.offsetWidth * 0.004 * 100) / 100;
                }
                setAnimationCurrent(animation);
                setEditorTab('animation');
                return animationItem;
              });
              return {
                ...item,
                animations: animationsNew,
              };
            } else {
              return item;
            }
          });

          setTimelines(timelinesNew);
        }}
        onClick={() => {
          setCssSelector({enable: false, animationId: ''});
          setAnimationCurrent(animation);
          setEditorTab('animation');
        }}
        onContextMenu={props.onContextMenu}
      >
        <div className="animation__thumb">
          <div className="animation__thumb-inner">{animation.selector ? animation.selector.split(',').join(', ') : 'Selector'}</div>
        </div>
      </Rnd>
    </div>
  );
}

export default Animation;
