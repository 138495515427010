import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { finder } from '@medv/finder';
import Snackbar from '@mui/material/Snackbar';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash.debounce';
import { Config } from '../../Config';
import { createGsapCode } from '../../Utils';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Panel from '../Panel/Panel';
import EditorContext from '../EditorContext/EditorContext';
import Timeline from '../Timeline/Timeline';
import IconButton from '@mui/material/IconButton';
import Iconify from '../Iconify/Iconify';
import AppContext from '../AppContext/AppContext';

import '../../assets/css/editor.scss';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3364E3',
      dark: '#3364E3',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
});

function Editor() {
  const { state } = useContext(AppContext);
  const user = state.user;
  const license = state.license;
  let { projectId, pageId } = useParams();
  const [project, setProject] = useState<any>({});
  const [page, setPage] = useState<any>([]);
  const [timelines, setTimelines] = useState<any>([]);
  const [timeline, setTimeline] = useState<any>({});
  const [timelineChanged, setTimelineChanged] = useState<boolean>(false);
  const [timelineClicked, setTimelineClicked] = useState<any>({});
  const [animationCurrent, setAnimationCurrent] = useState<any>([]);
  const [openModalTimelines, setOpenModalTimelines] = useState(true);
  const [openModalExport, setOpenModalExport] = useState<boolean>(false);
  const [error, setError] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cssSelector, setCssSelector] = useState<any>({ enable: false, controlId: '', type: '' });
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [removedSelector, setRemovedSelector] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [importTimeline, setImportTimeline] = useState<boolean>(false);
  const [timelinesJson, setTimelinesJson] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [device, setDevice] = useState<string>('desktop');
  const [repeatTimeline, setRepeatTimeline] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [gsapCode, setGsapCode] = useState<any>({ code: '', libs: '' });
  const [scrollMarkers, setScrollMarkers] = useState<any>({start:{}, end:{}});
  const [livePreview, setLivePreview] = useState<boolean>(false);
  const [editorTab, setEditorTab] = useState<string>('timeline');
  const [selectorPopup, setSelectorPopup] = useState<any>({ open: false, position: { x:null, y:null }, current: [], parent: [] });

  useEffect(() => {
    document.body.classList.add("body-editor");
    
    return () => {
       document.body.classList.remove("body-editor");
    }
 }, [])

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  let iframeRef = useRef<HTMLIFrameElement | null>(null);

  const generateKey = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const addTimeline = () => {
    const timelinesNew = [...timelines];
    const timelineId = generateKey();
    const timeline = {
      id: timelineId,
      name: timelineId,
      animations: [],
      trigger: 'page',
      mobile: true,
      tablet: true,
      desktop: true,
    };

    timelinesNew.push(timeline);
    setTimelines(timelinesNew);
    setTimeline(timeline);
    setAnimationCurrent('');
  };

  const getPage = useCallback(async () => {
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/pages/' + pageId,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      setPage(res.data.data.page);
      if (res.data.data.page.timelines) {
        setTimelines(res.data.data.page.timelines);
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [pageId]);

  useEffect(() => {
    getPage();
  }, [getPage]);

  const getProject = useCallback(async () => {
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/projects/' + projectId,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      setProject(res.data.data.project);
    } catch (error: any) {
      console.log(error);
    }
  }, [projectId]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  const editorValue = {
    project,
    setProject,
    page,
    setPage,
    error,
    setError,
    loading,
    setLoading,
    timelines,
    setTimelines,
    timeline,
    setTimeline,
    timelineChanged,
    setTimelineChanged,
    animationCurrent,
    setAnimationCurrent,
    iframeRef,
    iframeLoaded,
    setIframeLoaded,
    openModalTimelines,
    setOpenModalTimelines,
    openModalExport,
    setOpenModalExport,
    cssSelector,
    setCssSelector,
    selectorPopup,
    setSelectorPopup,
    removedSelector,
    setRemovedSelector,
    device,
    setDevice,
    repeatTimeline,
    setRepeatTimeline,
    fullScreen,
    setFullScreen,
    livePreview,
    setLivePreview,
    gsapCode,
    setGsapCode,
    editorTab,
    setEditorTab,
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      const iframeDocument = iframe.contentDocument;
      const iframeWindow:any = iframe.contentWindow;
      if (cssSelector.enable) {
        if (iframeDocument && iframeWindow) {
          const iframeBody = iframeDocument.getElementsByTagName('body')[0];
          iframeWindow.cssSelectorEnable = true;

          const handleClickIframe = (event: MouseEvent) => {
            event.preventDefault();
            const element = event.target as HTMLElement;
            if (element.id !== 'litemove-selector-popup' && !element.closest('#litemove-selector-popup')) {
              const parent = element.parentElement;
              const selectorCurrent = finder(element, {
                root: iframeBody,
              });

              let selectorCurrentList = [selectorCurrent];
              if (selectorCurrent.includes(':nth-child')) {
                // @ts-ignore
                selectorCurrentList.push(selectorCurrent.replaceAll(':nth-child','').replaceAll('(','/').replaceAll(')','/').split('/').filter((_, index) => index%2 === 0).join(''));
              }
              selectorCurrentList = [...selectorCurrentList, ...Array.from(element.classList).map((item) => `.${item}`)];

              let selectorParentList:any = [];
              const selectorParent = parent ? finder(parent, {
                root: iframeBody,
              }) : null;
              if (selectorParent) {
                selectorParentList = [selectorParent];
                if (selectorParent.includes(':nth-child')) {
                  // @ts-ignore
                  selectorParentList.push(selectorParent.replaceAll(':nth-child','').replaceAll('(','/').replaceAll(')','/').split('/').filter((_, index) => index%2 === 0).join(''));
                }
                if (parent) {
                  selectorParentList = [...selectorParentList, ...Array.from(parent.classList).map((item) => `.${item}`)];
                }
              }

              // @ts-ignore
              iframeWindow.litemoveSelectorPopup({ open: true, position: { x: event.pageX, y: event.pageY }, current: selectorCurrentList, parent: selectorParentList });
            }
            
            const selector = element.getAttribute('data-litemove-selector-item');
            if (selector) {
              if (cssSelector.enable) {
                switch (cssSelector.type) {
                  case 'animation':
                    const timelinesNew = timelines.map((item: any) => {
                      if (item.id === timeline.id) {
                        let animationsNew = item.animations.map((animationItem: any) => {
                          if (animationItem.id === animationCurrent.id) {
                            animationItem[cssSelector.controlId] =
                              animationItem[cssSelector.controlId]?.trim() === '' ? selector : (cssSelector.multiple ? Array.from(new Set((animationItem[cssSelector.controlId] + ',' + selector).split(','))).join(',') : selector);
                          }
                          return animationItem;
                        });
                        setTimeline({
                          ...item,
                          animations: animationsNew,
                        });
                        return {
                          ...item,
                          animations: animationsNew,
                        };
                      } else {
                        return item;
                      }
                    });

                    setTimelines(timelinesNew);
                    setCssSelector({ enable: false, controlId: '', type: '' });
                    iframeWindow.cssSelectorEnable = false;
                    break;

                  case 'timeline':
                    const timelinesUpdate = timelines.map((item: any) => {
                      if (item.id === timeline.id) {
                        item[cssSelector.controlId] = selector;
                        setTimeline(item);
                      }
                      return item;
                    });
        
                    setTimelines(timelinesUpdate);
                    setCssSelector({ enable: false, controlId: '', type: '' });
                    iframeWindow.cssSelectorEnable = false;
                    break;
                }
              }

              // @ts-ignore
              iframeWindow.litemoveSelectorPopup({open: false, position: { x: 0, y: 0 }, current: [], parent: []});
              // @ts-ignore
              iframeWindow.litemoveSelectorRect({hide: true});
              iframeBody.removeEventListener('mouseover', (event) => {
                handleHoverIframe(event, 'mouseover');
              });
              iframeBody.removeEventListener('mouseout', (event) => {
                handleHoverIframe(event, 'mouseout');
              });
            }
          };

          const handleHoverIframe = (event: MouseEvent, mouseEvent: string) => {
            debounce((event) => {
              if (event.target.id !== 'litemove-selector-popup' && !event.target.closest('#litemove-selector-popup')) {
                if (iframeWindow.cssSelectorEnable) {
                  const element = event.target as HTMLElement;
                  const selector = finder(element, {
                    root: iframeBody,
                  });
                  iframeDocument.querySelectorAll(selector).forEach((item: any) => {
                    switch (mouseEvent) {
                      case 'mouseover':
                        // item.style.outline = '1px solid #3364E3';
                        // @ts-ignore
                        iframeWindow.litemoveSelectorRect(item.getBoundingClientRect());
                        break;
                      case 'mouseout':
                        // item.style.outline = 'none';
                        break;
                    }
                  });
                }
              }
            }, 200)(event);
          };
          
          iframeBody.addEventListener('mouseover', (event) => {
            handleHoverIframe(event, 'mouseover');
          });
          iframeBody.addEventListener('mouseout', (event) => {
            handleHoverIframe(event, 'mouseout');
          });

          iframeBody.addEventListener('click', handleClickIframe);

          return () => {
            iframeBody.removeEventListener('click', handleClickIframe);
            iframeBody.removeEventListener('mouseover', (event) => {
              handleHoverIframe(event, 'mouseover');
            });
            iframeBody.removeEventListener('mouseout', (event) => {
              handleHoverIframe(event, 'mouseout');
            });
          };
        }
      } else {
        // @ts-ignore
        iframeWindow.litemoveSelectorPopup({open: false, position: { x: 0, y: 0 }, current: [], parent: []});
        iframeWindow.cssSelectorEnable = false;
      }
    }
  }, [cssSelector]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframeLoaded) {
      const iframeDocument = iframe.contentDocument;
      if (iframeDocument) {
        const iframeBody = iframeDocument.getElementsByTagName('body')[0];
        if (iframeBody) {
          
        }
      }
    }
  }, [iframeLoaded]);

  useEffect(() => {
    if (timeline.id && timeline.trigger === 'scroll') {
      const scrollTriggerStartEndViewportStart = timeline.scrollTriggerStartEndViewportStart ? timeline.scrollTriggerStartEndViewportStart : 'bottom';
      const scrollTriggerStartEndViewportEnd = timeline.scrollTriggerStartEndViewportEnd ? timeline.scrollTriggerStartEndViewportEnd : 'top';
      let startCss = {};
      let endCss = {};
      let scrollTriggerStartEndViewportOffsetStart = timeline.scrollTriggerStartEndViewportOffsetStart ? timeline.scrollTriggerStartEndViewportOffsetStart : '';
      let scrollTriggerStartEndViewportOffsetEnd = timeline.scrollTriggerStartEndViewportOffsetEnd ? timeline.scrollTriggerStartEndViewportOffsetEnd : '';

      switch (scrollTriggerStartEndViewportStart) {
        case 'top':
          if (scrollTriggerStartEndViewportOffsetStart.includes('-')) {
            scrollTriggerStartEndViewportOffsetStart = '+' + scrollTriggerStartEndViewportOffsetStart.replace('-', '');
          } else {
            scrollTriggerStartEndViewportOffsetStart = '-' + scrollTriggerStartEndViewportOffsetStart.replace('+', '');
          }
          startCss = {top: (timeline.scrollTriggerStartEndViewportOffsetStart ? scrollTriggerStartEndViewportOffsetStart : 0), borderTop: 'solid 1px green' };
          break;
        case 'center':
          if (scrollTriggerStartEndViewportOffsetStart.includes('-')) {
            scrollTriggerStartEndViewportOffsetStart = '+ ' + scrollTriggerStartEndViewportOffsetStart.replace('-', '');
          } else {
            scrollTriggerStartEndViewportOffsetStart = '- ' + scrollTriggerStartEndViewportOffsetStart.replace('+', '');
          }
          startCss = {bottom: (timeline.scrollTriggerStartEndViewportOffsetStart ? 'calc(50% ' + scrollTriggerStartEndViewportOffsetStart + ')' : '50%'), borderBottom: 'solid 1px green' };
          break;
        case 'bottom':
          if (scrollTriggerStartEndViewportOffsetStart.includes('-')) {
            scrollTriggerStartEndViewportOffsetStart = '+' + scrollTriggerStartEndViewportOffsetStart.replace('-', '');
          } else {
            scrollTriggerStartEndViewportOffsetStart = '-' + scrollTriggerStartEndViewportOffsetStart.replace('+', '');
          }
          startCss = {bottom: (timeline.scrollTriggerStartEndViewportOffsetStart ? scrollTriggerStartEndViewportOffsetStart : 0), borderBottom: 'solid 1px green' };
          break;
      }

      switch (scrollTriggerStartEndViewportEnd) {
        case 'top':
          if (scrollTriggerStartEndViewportOffsetEnd.includes('-')) {
            scrollTriggerStartEndViewportOffsetEnd = '+' + scrollTriggerStartEndViewportOffsetEnd.replace('-', '');
          } else {
            scrollTriggerStartEndViewportOffsetEnd = '-' + scrollTriggerStartEndViewportOffsetEnd.replace('+', '');
          }
          endCss = {top: (timeline.scrollTriggerStartEndViewportOffsetEnd ? scrollTriggerStartEndViewportOffsetEnd : 0), borderTop: 'solid 1px red'};
          break;
        case 'center':
          if (scrollTriggerStartEndViewportOffsetEnd.includes('-')) {
            scrollTriggerStartEndViewportOffsetEnd = '+ ' + scrollTriggerStartEndViewportOffsetEnd.replace('-', '');
          } else {
            scrollTriggerStartEndViewportOffsetEnd = '- ' + scrollTriggerStartEndViewportOffsetEnd.replace('+', '');
          }
          endCss = {top: (timeline.scrollTriggerStartEndViewportOffsetEnd ? 'calc(50% ' + scrollTriggerStartEndViewportOffsetEnd + ')' : '50%'), borderTop: 'solid 1px red'};
          break;
        case 'bottom':
          if (scrollTriggerStartEndViewportOffsetEnd.includes('-')) {
            scrollTriggerStartEndViewportOffsetEnd = '+' + scrollTriggerStartEndViewportOffsetEnd.replace('-', '');
          } else {
            scrollTriggerStartEndViewportOffsetEnd = '-' + scrollTriggerStartEndViewportOffsetEnd.replace('+', '');
          }
          endCss = {bottom: (timeline.scrollTriggerStartEndViewportOffsetEnd ? scrollTriggerStartEndViewportOffsetEnd : 0), borderBottom: 'solid 1px red'};
          break;
      }

      setScrollMarkers({start: startCss, end: endCss});
    } else {
      setScrollMarkers({});
    }
  }, [timelines, timeline]);

  return (
    <>
    {!license.expired && (
      <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <EditorContext.Provider value={editorValue}>
          <div className={fullScreen ? 'editor editor--full' : 'editor'}>
            <Panel />
            <div className="editor-right">
              <div className='editor-iframe-wrapper'>
                {page && page.html && (
                  <iframe
                    src={'/api/v1/html/' + page.html + '?v=' + (page.htmlVersion ? page.htmlVersion : 1)}
                    title="iframe"
                    className={'editor-iframe editor-iframe--' + device}
                    ref={iframeRef}
                    onLoad={() => {
                      setIframeLoaded(true);
                      window.postMessage({type: 'litemoveIframeLoaded', data: page}, '*');
                    }}
                    sandbox="allow-scripts allow-same-origin allow-forms"
                    referrerPolicy="origin"
                  ></iframe>
                )}
                {(timeline.id && timeline.trigger === 'scroll') && (
                  <>
                    <div className="scrollTrigger-markers scrollTrigger-markers--end" style={scrollMarkers.end}>
                      End Viewport
                    </div>
                    <div className="scrollTrigger-markers scrollTrigger-markers--start" style={scrollMarkers.start}>
                      Start Viewport
                    </div>
                  </>
                )}
              </div>
              <Timeline />
            </div>
            <Dialog
              open={openModalTimelines}
              onClose={() => {
                if (timeline.id) {
                  setOpenModalTimelines(false);
                }
              }}
            >
              <DialogTitle>
                Timeline
                {timeline.id && (
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenModalTimelines(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 12,
                    }}
                  >
                    <Iconify icon="material-symbols:close" color="#bfc2c5" width={22} height={22} />
                  </IconButton>
                )}
              </DialogTitle>
              <List sx={{ pt: 0 }}>
                {timelines.map((timelineItem: any) => (
                  <ListItem
                    button
                    key={timelineItem.id}
                    onClick={() => {
                      setTimeline(timelineItem);
                      setTimelineChanged(true);
                      setAnimationCurrent('');
                      setOpenModalTimelines(false);
                      setImportTimeline(false);
                      setTimelinesJson('');
                    }}
                  >
                    <div className="timeline-item">
                      <div className="timeline-item__name">{timelineItem.name}</div>
                      <div
                        className="timeline-item__icon"
                        aria-describedby="timelinePopover"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePopoverOpen(e);
                          setTimelineClicked(timelineItem);
                        }}
                      >
                        <Iconify icon="ph:dots-three-outline-fill" />
                      </div>
                    </div>
                  </ListItem>
                ))}
              </List>
              <div className="dialog__footer">
                {importTimeline && (
                  <div className="dialog__footer-import">
                    <textarea
                      className="dialog__footer-textarea"
                      value={timelinesJson}
                      onChange={(e) => setTimelinesJson(e.target.value.trim())}
                      rows={3}
                      placeholder="Paste your timeline json here"
                    ></textarea>
                  </div>
                )}
                <div className="dialog__footer-buttons">
                  {importTimeline && (
                    <Button
                      className="dialog__footer-button button-gray"
                      variant="contained"
                      onClick={() => {
                        try {
                          setImportTimeline(false);
                          const timelinesImport = JSON.parse(timelinesJson);
                          if (timelinesImport.length > 0) {
                            const timelinesImportNew: any = [];
                            timelinesImport.forEach((timelineItem: any) => {
                              timelineItem.id = generateKey();
                              timelineItem.name = timelineItem.name + ' (Copy)';
                              timelinesImportNew.push(timelineItem);
                            });
                            setTimelines([...timelines, ...timelinesImportNew]);
                            setTimelinesJson('');
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      Click to import
                    </Button>
                  )}
                  {!importTimeline && (
                    <Button
                      className="dialog__footer-button button-gray"
                      variant="contained"
                      onClick={() => {
                        try {
                          setImportTimeline(true);
                          const timelinesNew = JSON.parse(timelinesJson);
                          if (timelinesNew.length > 0) {
                            timelinesNew.forEach((timeline: any) => {});
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      Import
                    </Button>
                  )}
                  <Button
                    className="dialog__footer-button"
                    variant="contained"
                    onClick={() => {
                      addTimeline();
                      setTimelineChanged(true);
                      setOpenModalTimelines(false);
                    }}
                  >
                    Create new
                  </Button>
                </div>
              </div>

              <Popover
                id="timelinePopover"
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className="popover-list">
                  <div
                    className="popover-list__item"
                    onClick={() => {
                      const animationsCopy = timelineClicked.animations.map((animationItem: any) => {
                        return {
                          ...animationItem,
                          id: generateKey(),
                          from: {...animationItem.from},
                          to: {...animationItem.to},
                        };
                      });

                      const timelineDuplicate = {
                        ...timelineClicked,
                        animations: animationsCopy,
                        id: generateKey(),
                        name: timelineClicked.name + ' (Copy)',
                      };
                      setTimelines([...timelines, timelineDuplicate]);
                      handlePopoverClose();
                    }}
                  >
                    <Iconify icon="ph:copy" />
                    <span className="popover-list__item-text">Duplicate</span>
                  </div>
                  <div
                    className="popover-list__item"
                    onClick={() => {
                      navigator.clipboard.writeText(JSON.stringify([timelineClicked]));
                      setOpenSnackbar(true);
                      handlePopoverClose();
                    }}
                  >
                    <Iconify icon="ph:export" />
                    <span className="popover-list__item-text">Export</span>
                  </div>
                  <div
                    className="popover-list__item"
                    onClick={() => {
                      if (timelineClicked.id === timeline.id) {
                        setTimeline({
                          id: '',
                          name: '',
                          animations: [],
                        });
                        setAnimationCurrent('');
                      }
                      const timelinesNew = timelines.filter((item: any) => item.id !== timelineClicked.id);
                      setTimelines(timelinesNew);
                      setTimelineChanged(true);
                      handlePopoverClose();
                    }}
                  >
                    <Iconify icon="ph:trash" />
                    <span className="popover-list__item-text">Delete</span>
                  </div>
                </div>
              </Popover>
            </Dialog>

            <Dialog open={openModalExport} onClose={() => setOpenModalExport(false)}>
              <DialogTitle>
                Export Javascript
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenModalExport(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 12,
                  }}
                >
                  <Iconify icon="material-symbols:close" color="#bfc2c5" width={22} height={22} />
                </IconButton>
              </DialogTitle>
              <div className="dialog__content">
                <div className='flex flex-space-between align-center margin-bottom-10'>
                  <div>Javascript Library</div>
                  <div
                    className="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(gsapCode.libs);
                      setOpenSnackbar(true);
                    }}
                  >Copy</div>
                </div>

                <div className='code-wrapper margin-bottom-20'>
                  <CodeEditor
                    id="scrollTriggerCustom"
                    value={gsapCode.libs}
                    language="js"
                    data-color-mode="dark"
                  />
                </div>
                
                <div className='flex flex-space-between align-center margin-bottom-10'>
                  <div>Javascript Code</div>
                  <div
                    className="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(gsapCode.code);
                      setOpenSnackbar(true);
                    }}
                  >Copy</div>
                </div>

                <div className='code-wrapper'>
                  <CodeEditor
                    id="scrollTriggerCustom"
                    value={gsapCode.code}
                    language="js"
                    data-color-mode="dark"
                  />
                </div>
              </div>
              <DialogTitle>Export Timeline</DialogTitle>
              <div className='list-limited'>
                <List sx={{ pt: 0 }}>
                  {timelines.map((timeline: any) => (
                    <ListItem
                      button
                      key={timeline.id}
                      onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify([timeline]));
                        setOpenSnackbar(true);
                      }}
                    >
                      {timeline.name}
                    </ListItem>
                  ))}
                  <ListItem
                    button
                    key={timeline.id}
                    onClick={() => {
                      navigator.clipboard.writeText(JSON.stringify(timelines));
                      setOpenSnackbar(true);
                    }}
                  >
                    All timelines
                  </ListItem>
                </List>
              </div>
            </Dialog>
            <Snackbar
              open={openSnackbar}
              onClose={() => setOpenSnackbar(false)}
              message="Copied to clipboard"
              autoHideDuration={1000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            />
          </div>
          
          {(!iframeLoaded && !timeline.id) && (
            <div className="editor-loading">
              <CircularProgress />
              <div className="editor-loading__content">
                Loading experience...
              </div>
            </div>
          )}

          <div className="litemove-invisible"></div>
        </EditorContext.Provider>
      </ThemeProvider>
      </>
    )}
    </>
  );
}

export default Editor;
