import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AppContext from '../AppContext/AppContext';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import gravatarUrl from 'gravatar-url';
import { Config } from '../../Config';

const MENU_OPTIONS = [
    {
        label: 'Account',
        type: 'link',
        url: '/profile',
    },
    {
        label: 'Contact Us',
        type: 'a',
        url: 'https://softlite.io/litemove/',
    },
];

export default function UserPopover() {
    const [open, setOpen] = useState(null);
    const { state, dispatch } = useContext(AppContext);
    const user = state.user;
    const navigate = useNavigate();

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch({ type: 'CURRENT_USER', payload: null });
        navigate('/');
    };

    let avartarGravatar = '';

    try {
        avartarGravatar = user ? gravatarUrl(user.email, { size: 200 }) : '';
    } catch (error) {
        console.log(error);
    }
    let avatarUrl = user?.avatar ? user.avatar : avartarGravatar;

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Avatar src={avatarUrl} alt="photoURL" />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.userName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        option.type === 'a' ? (
                        <a href={option.url} key={option.label} target="_blank">
                            <MenuItem>
                                {option.label}
                            </MenuItem>
                        </a>
                        ) : (
                        <Link to={option.url} key={option.label} onClick={handleClose}>
                            <MenuItem>
                                {option.label}
                            </MenuItem>
                        </Link>
                        )
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </Popover>
        </>
    );
}
