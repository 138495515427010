import { createContext } from 'react';

interface EditorContextType {
	project: any;
	setProject: (project: any) => void;
	page: {_id: string; html: string;};
	setPage: (page: Object) => void;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	timelines: any;
	setTimelines: (timelines: any) => void;
	timeline: any;
	setTimeline: (timeline: any) => void;
	timelineChanged: boolean;
	setTimelineChanged: (timelineChanged: boolean) => void;
	animationCurrent: any;
	setAnimationCurrent: (animation: any) => void;
	iframeRef: any;
	iframeLoaded: boolean;
	setIframeLoaded: (iframeLoaded: boolean) => void;
	openModalTimelines: boolean;
	setOpenModalTimelines: (openModalTimelines: boolean) => void;
	openModalExport: boolean;
	setOpenModalExport: (openModalExport: boolean) => void;
	cssSelector: {enable: boolean; controlId: string; type: string; multiple?: boolean};
	setCssSelector: (cssSelector: Object) => void;
	selectorPopup: { open: boolean, position: any, current: any, parent: any };
	setSelectorPopup: (selectorPopup: Object) => void;
	removedSelector: string,
	setRemovedSelector: (removedSelector: string) => void,
	device: string,
	setDevice: (device: string) => void,
	repeatTimeline: boolean,
	setRepeatTimeline: (repeatTimeline: boolean) => void,
	fullScreen: boolean,
	setFullScreen: (fullScreen: boolean) => void,
	livePreview: boolean,
	setLivePreview: (livePreview: boolean) => void,
	gsapCode: { code: string; libs: string },
	setGsapCode: (gsapCode: object) => void,
	editorTab: string,
	setEditorTab: (editorTab: string) => void,
};

// set the defaults
const EditorContext = createContext<EditorContextType>({
	project: {},
	setProject: () => {},
	page: {_id: '', html: ''},
	setPage: () => {},
	loading: false,
	setLoading: () => {},
  timelines: [],
  setTimelines: () => {},
  timeline: [],
  setTimeline: () => {},
	timelineChanged: false,
	setTimelineChanged: () => {},
  animationCurrent: '',
  setAnimationCurrent: () => {},
  iframeRef: null,
	iframeLoaded: false,
	setIframeLoaded: () => {},
	openModalTimelines: false,
	setOpenModalTimelines: () => {},
	openModalExport: false,
	setOpenModalExport: () => {},
	cssSelector: {enable: false, controlId: '', type: '', multiple: false},
	setCssSelector: () => {},
	selectorPopup: { open: false, position: { x:null, y:null }, current: [], parent: [] },
	setSelectorPopup: () => {},
	removedSelector: '',
	setRemovedSelector: () => {},
	device: 'desktop',
	setDevice: () => {},
	repeatTimeline: false,
	setRepeatTimeline: () => {},
	fullScreen: false,
	setFullScreen: () => {},
	livePreview: false,
	setLivePreview: () => {},
	gsapCode: { code: '', libs: '' },
	setGsapCode: () => {},
	editorTab: 'timeline',
	setEditorTab: () => {},
});

export default EditorContext;