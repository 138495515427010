import { Config } from './Config';

export const generateSript = (script: any) => {
	return `<script src="${script.src}" id="${script.id}"></script>\n`;
}

export const litemoveStringify = (object: any) => {
	const stringFunctions:any = [];

	let objectString = JSON.stringify(
		object,
		function(key, val) {
			if (typeof val === 'function') {
				stringFunctions.push('' + val);
				return '' + val;
			} else {
				return val;
			}
		})
		.replace(/"([^"]+)":/g, '$1:');

	stringFunctions.forEach((func:any, index:any) => {
		objectString = objectString.replace('"' + func + '"', func);
	});

	return objectString;
}

export const createGsapCode = (project: any, timelines: any, editor: boolean, livePreview?: boolean) => {
	let code = '';

	const scripts:any = {
		gsap: {
			src: Config.PUBLIC_URL + '/gsap/gsap.min.js',
			id: 'litemove-gsap-js',
			registerPlugin: false,
		},
		scrollTrigger: {
			src: Config.PUBLIC_URL + '/gsap/ScrollTrigger.min.js',
			id: 'litemove-ScrollTrigger-js',
			registerPlugin: 'ScrollTrigger',
		},
		splitText: {
			src: Config.PUBLIC_URL + '/gsap/SplitText.min.js',
			id: 'litemove-SplitText-js',
			registerPlugin: 'SplitText',
		},
		scrollSmoother: {
			src: Config.PUBLIC_URL + '/gsap/ScrollSmoother.min.js',
			id: 'litemove-ScrollSmoother-js',
			registerPlugin: 'ScrollSmoother',
		},
		flip: {
			src: Config.PUBLIC_URL + '/gsap/Flip.min.js',
			id: 'litemove-Flip-js',
			registerPlugin: 'Flip',
		}
	};

	let libs:any = ['gsap'];

	if (project.settings?.customJsBefore) {
		if (!editor) {
			code += `<script id="litemove-js-before">${project.settings?.customJsBefore}</script>\n`;
		} else {
			code += project.settings?.customJsBefore;
		}
	}

	if (project.settings?.scrollSmoother) {
		libs.push('scrollSmoother');

		const scrollSmootherOptions:any = {};
		scrollSmootherOptions['wrapper'] = project.settings.scrollSmootherWrapper ? project.settings.scrollSmootherWrapper : '#smooth-wrapper';
		scrollSmootherOptions['content'] = project.settings.scrollSmootherContent ? project.settings.scrollSmootherContent : '#smooth-content';
		if (project.settings?.scrollSmootherSmooth) {
			scrollSmootherOptions['smooth'] = project.settings.scrollSmootherSmooth;
		}
		if (project.settings?.scrollSmootherSmoothTouch) {
			scrollSmootherOptions['smoothTouch'] = project.settings.scrollSmootherSmoothTouch;
		}
		if (project.settings?.scrollSmootherSpeed) {
			scrollSmootherOptions['speed'] = project.settings.scrollSmootherSpeed;
		}
		if (project.settings?.scrollSmootherEffects) {
			scrollSmootherOptions['effects'] = project.settings.scrollSmootherEffects;
		}
		if (project.settings?.scrollSmootherCustom) {
			const scrollSmootherCustom = eval('({' + project.settings.scrollSmootherCustom.trim() + '})');
			for (const propertyCustom in scrollSmootherCustom) {
				scrollSmootherOptions[propertyCustom] = scrollSmootherCustom[propertyCustom];
			}
		}

		let scrollSmootherCode = 'window["animation_smoother"] = ScrollSmoother.create(' + litemoveStringify(scrollSmootherOptions) + ');';
		if (project.settings?.scrollSmootherAnchor) {
			scrollSmootherCode += `window.addEventListener("load", (e) => { if(window.location.hash) {window["animation_smoother"].scrollTo(window.location.hash, true);}});`;
			scrollSmootherCode += `document.querySelectorAll('a[href*="#"]').forEach((anchor) => { anchor.addEventListener('click', (e) => { const anchorHref = anchor.getAttribute('href'); if (window.location.pathname === anchorHref.split('#')[0] || anchorHref.startsWith('#')) { e.preventDefault(); } const hash = '#' + anchorHref.split('#')[1]; window["animation_smoother"].scrollTo(hash, true); }); });`;
		}

		if (!editor) {
			code += `<script id="litemove-js-smoother">${scrollSmootherCode}</script>\n`;
		} else {
			code += scrollSmootherCode;
		}
	}                                                                                                                   

	if (!editor && !livePreview) {
		code += `<script id="litemove-js">`;
	}

	if (!editor || editor && livePreview) {
		code += `gsap.config({nullTargetWarn: false});`;
	}

	code += `gsap.defaults({transition:"unset"});`

	if (!editor) {
		code += `addEventListener("DOMContentLoaded", () => { document.body.style.overflowX="hidden"; `;
	}

	if (project.settings?.resetTimelinesOnWindowResize || project.settings?.resetTimelinesOnURLChangeNotHash || project.settings?.resetTimelinesOnHashChange) {

		if (project.settings?.resetTimelinesOnURLChangeNotHash) {
			code += `let litemoveCurrentUrl = location.href.replace(location.hash,"");`;
		}

		if (project.settings?.resetTimelinesOnHashChange) {
			code += `let litemoveCurrentHash = location.hash;`;
		}

		code += `let litemoveResizeTimer; function litemoveCreateTimelines() {`;

		if (!editor) {
			timelines.forEach((timeline: any) => {
				code += `if (window['litemove_${timeline.id}']) { window['litemove_${timeline.id}'].seek(0).clear(); delete window['litemove_${timeline.id}']; }`;
			});
		}
	}

	timelines.forEach((timeline: any) => {

		const timelineSettings:any = {};

		for (let i = 0; i < timeline?.animations?.length; i++) {
			const item = timeline?.animations[i];
			if (item.selector) {
				if (item.splitText) {
					if (!libs.includes('splitText')) {
						libs.push('splitText');
					}
					code += `window['litemove_${timeline.id}_st_${item.id}'] = new SplitText('${item.selector}', {type: '` + (!item.splitTextType || item.splitTextType === 'chars' ? 'chars,words' : item.splitTextType) + `'});`;
				}
			}
		}

		if (!editor || editor && livePreview) {
			if (!timeline.mobile || !timeline.tablet || !timeline.desktop) {
				let timelineMatchMedia = (timeline.mobile ? 'mobile' : '') + (timeline.tablet ? 'tablet' : '') + (timeline.desktop ? 'desktop' : '');
				let timelineMatchMediaQuery = '';
				const breakpointsMobile = project.settings?.breakpointsMobile ? project.settings?.breakpointsMobile : 767;
				const breakpointsTablet = project.settings?.breakpointsTablet ? project.settings?.breakpointsTablet : 991;
				const breakpointsDesktop = breakpointsTablet + 1;
				
				switch (timelineMatchMedia) {
					case 'mobile':
						timelineMatchMediaQuery = '(max-width: ' + breakpointsMobile + 'px)';
						break;
					case 'tablet':
						timelineMatchMediaQuery = '(min-width: ' + (breakpointsMobile + 1) + 'px) and (max-width: ' + breakpointsTablet + 'px)';
						break;
					case 'desktop':
						timelineMatchMediaQuery = '(min-width: ' + breakpointsDesktop + 'px)';
						break;
					case 'mobiletablet':
						timelineMatchMediaQuery = '(max-width: ' + breakpointsTablet + 'px)';
						break;
					case 'mobiledesktop':
						timelineMatchMediaQuery = '(max-width: ' + breakpointsMobile + 'px) and (min-width: ' + breakpointsDesktop + 'px)';
						break;
					case 'tabletdesktop':
						timelineMatchMediaQuery = '(min-width: ' + (breakpointsMobile + 1) + 'px)';
						break;
				}

				code += `if (matchMedia("${timelineMatchMediaQuery}").matches) {`;
			}

			if (timeline.trigger === 'page' && timeline.pageStart === 'contentLoaded' && !editor) {
				code += `addEventListener("load", e => {`;
			}

			let triggerSelector = timeline.triggerSelector ? timeline.triggerSelector : (timeline.animations.length > 0 ? timeline.animations[0].selector : '');

			try {
				document.querySelector(triggerSelector);
			} catch (err) {
				triggerSelector = '';
			}

			if (timeline.trigger === 'scroll' && triggerSelector !== '') {

				if (!libs.includes('scrollTrigger')) {
					libs.push('scrollTrigger');
				}

				const scrollTriggerOptions:any = {
					trigger: triggerSelector,
				}

				const scrollTriggerStart = {
					element: timeline.scrollTriggerStartEndTriggerElementStart ? timeline.scrollTriggerStartEndTriggerElementStart : 'top',
					viewport: timeline.scrollTriggerStartEndViewportStart ? timeline.scrollTriggerStartEndViewportStart : 'bottom'
				};
				if (timeline.scrollTriggerStartEndTriggerElementOffsetStart) {
					scrollTriggerStart.element = timeline.scrollTriggerStartEndTriggerElementOffsetStart.includes('-') ? scrollTriggerStart.element + '-=' + timeline.scrollTriggerStartEndTriggerElementOffsetStart.replace('-','') : scrollTriggerStart.element + `+=${timeline.scrollTriggerStartEndTriggerElementOffsetStart}`;
				}
				if (timeline.scrollTriggerStartEndViewportOffsetStart) {
					scrollTriggerStart.viewport = timeline.scrollTriggerStartEndViewportOffsetStart.includes('-') ? scrollTriggerStart.viewport + '-=' + timeline.scrollTriggerStartEndViewportOffsetStart.replace('-','') : scrollTriggerStart.viewport + `+=${timeline.scrollTriggerStartEndViewportOffsetStart}`;
				}
				const scrollTriggerEnd = {
					element: timeline.scrollTriggerStartEndTriggerElementEnd ? timeline.scrollTriggerStartEndTriggerElementEnd : 'bottom',
					viewport: timeline.scrollTriggerStartEndViewportEnd ? timeline.scrollTriggerStartEndViewportEnd : 'top'
				};
				if (timeline.scrollTriggerStartEndTriggerElementOffsetEnd) {
					scrollTriggerEnd.element = timeline.scrollTriggerStartEndTriggerElementOffsetEnd.includes('-') ? scrollTriggerEnd.element + '-=' + timeline.scrollTriggerStartEndTriggerElementOffsetEnd.replace('-','') : scrollTriggerEnd.element + `+=${timeline.scrollTriggerStartEndTriggerElementOffsetEnd}`;
				}
				if (timeline.scrollTriggerStartEndViewportOffsetEnd) {
					scrollTriggerEnd.viewport = timeline.scrollTriggerStartEndViewportOffsetEnd.includes('-') ? scrollTriggerEnd.viewport + '-=' + timeline.scrollTriggerStartEndViewportOffsetEnd.replace('-','') : scrollTriggerEnd.viewport + `+=${timeline.scrollTriggerStartEndViewportOffsetEnd}`;
				}

				scrollTriggerOptions.start = scrollTriggerStart.element + ' ' + scrollTriggerStart.viewport;
				scrollTriggerOptions.end = scrollTriggerEnd.element + ' ' + scrollTriggerEnd.viewport;

				if (timeline.scrollTriggerToggleActions) {
					const toggleActions = {
						onEnter: timeline.scrollTriggerToggleActionsonEnter ? timeline.scrollTriggerToggleActionsonEnter : 'play',
						onLeave: timeline.scrollTriggerToggleActionsonLeave ? timeline.scrollTriggerToggleActionsonLeave : 'none',
						onEnterBack: timeline.scrollTriggerToggleActionsonEnterBack ? timeline.scrollTriggerToggleActionsonEnterBack : 'none',
						onLeaveBack: timeline.scrollTriggerToggleActionsonLeaveBack ? timeline.scrollTriggerToggleActionsonLeaveBack : 'none',
					};
					scrollTriggerOptions.toggleActions = toggleActions.onEnter + ' ' + toggleActions.onLeave + ' ' + toggleActions.onEnterBack + ' ' + toggleActions.onLeaveBack;
				}

				if (timeline.scrollTriggerScrub) {
					scrollTriggerOptions.scrub = timeline.scrollTriggerScrub;
				}

				if (timeline.scrollTriggerPin) {
					scrollTriggerOptions.pin = true;
					if (timeline.scrollTriggerPinSelector) {
						scrollTriggerOptions.pin = timeline.scrollTriggerPinSelector;
					}
				}

				if (timeline.scrollTriggerToggleClass) {
					scrollTriggerOptions.toggleClass = timeline.scrollTriggerToggleClass;
				}

				if (timeline.scrollTriggerCustom) {
					const scrollTriggerCustom = eval('({' + timeline.scrollTriggerCustom.trim() + '})');
					
					for (const propertyCustom in scrollTriggerCustom) {
						scrollTriggerOptions[propertyCustom] = scrollTriggerCustom[propertyCustom];
					}
				}

				if (timeline.endTriggerSelector) {
					scrollTriggerOptions.endTrigger = timeline.endTriggerSelector;
				}
				
				timelineSettings.scrollTrigger = scrollTriggerOptions;
			}

			if (['click','hover','pageExit'].includes(timeline.trigger)) {
				timelineSettings.paused = true;
			}

			const timelineSettingsString = litemoveStringify(timelineSettings);

			code += `window['litemove_${timeline.id}'] = gsap.timeline(${timelineSettingsString});`;

			if (timeline.trigger === 'click' && triggerSelector !== '') {
				const secondReverse = timeline.secondClickReverse ? timeline.secondClickReverse : false;
				const secondRestart = timeline.secondClickRestart ? timeline.secondClickRestart : false;
				code += `document.querySelectorAll('${triggerSelector}').forEach(el=>{el.addEventListener('click', e => { e.preventDefault(); `;
				if (secondReverse) {
					code += `e.detail && e.detail!==1 || window['litemove_${timeline.id}'].progress() === 1 ? window['litemove_${timeline.id}'].reverse() : window['litemove_${timeline.id}'].play();`;
				}
				if (secondRestart) {
					code += `e.detail && e.detail!==1 || window['litemove_${timeline.id}'].progress() === 1 ? window['litemove_${timeline.id}'].restart() : window['litemove_${timeline.id}'].play();`;
				}
				if (!secondReverse && !secondRestart) {
					code += `window['litemove_${timeline.id}'].play();`;
				}
				code += `}) });`;
			}

			if (timeline.trigger === 'hover' && triggerSelector !== '') {
				const secondReverse = timeline.mouseLeaveReverse ? timeline.mouseLeaveReverse : false;
				const secondRestart = timeline.mouseLeaveRestart ? timeline.mouseLeaveRestart : false;
				code += `document.querySelectorAll('${triggerSelector}').forEach(el=>{el.addEventListener('mouseenter', e => { window['litemove_${timeline.id}'].play(); });`;

				if (secondReverse || secondRestart) {
					code += `el.addEventListener('mouseleave', e => {`;
					if (secondReverse) {
						code += `window['litemove_${timeline.id}'].reverse();`;
					}
					if (secondRestart) {
						code += `window['litemove_${timeline.id}'].restart();`;
					}
					code += `});`;
				}
				code += `});`;
			}

			if (timeline.trigger === 'pageExit') {
				// code += `document.querySelectorAll('a').forEach(el=>{el.addEventListener('click', e => { e.preventDefault(); window['litemove_${timeline.id}'].play().call((() => location.href = void 0 !== this ? this : location.href)) }); });`;
				code += `document.querySelectorAll('a').forEach(el=>{el.addEventListener('click', e => { e.preventDefault(); window['litemove_${timeline.id}'].eventCallback('onComplete', () => { window.location.href = el.getAttribute('href'); }); window['litemove_${timeline.id}'].play(); }); });`;
			}
		}

		for (let i = 0; i < timeline?.animations?.length; i++) {
			const item = timeline?.animations[i];
			if (item.selector) {
				try {
					const selector = code.includes('litemove_' + timeline.id + '_st_' + item.id) ? 'litemove_' + timeline.id + '_st_' + item.id + '.' + item.splitTextType : item.selector;
					const itemFrom:any = {};
					const itemTo:any = {};

					if (!item.flip) {
						for (const property in item.from) {
							if (property === 'custom') {
								try {
									const customAttr = eval('({' + item.from[property].replace(/(\r\n|\n|\r)/gm, '').trim() + '})');
									for (const propertyCustom in customAttr) {
										itemFrom[propertyCustom] = customAttr[propertyCustom];
									}
								} catch (e) {
									console.log(e);
								}
							} else {
								itemFrom[property] = item.from[property];
							}
						}

						for (const property in item.to) {
							if (property === 'custom') {
								try {
									const customAttr = eval('({' + item.to[property].replace(/(\r\n|\n|\r)/gm, '').trim() + '})');
									for (const propertyCustom in customAttr) {
										itemTo[propertyCustom] = customAttr[propertyCustom];
									}
								} catch (e) {
									console.log(e);
								}
							} else {
								itemTo[property] = item.to[property];
							}
						}
					} else {
						libs.push('flip');
						let flipCode = '';

						if (['moveToTarget', 'moveToOrigin'].includes(item.flipMethod)) {
							flipCode += `const origin_flip_${item.id} = document.querySelector('${item.selector}'); const target_flip_${item.id} = document.querySelector('${item.flipTargetSelector}'); if (target_flip_${item.id} && origin_flip_${item.id}) { target_flip_${item.id}.setAttribute('data-flip-id','${item.id}'); origin_flip_${item.id}.setAttribute('data-flip-id','${item.id}'); const state_flip_${item.id} = Flip.getState('${item.flipTargetSelector}');`;
							if (item.flipMethod === 'moveToTarget') {
								flipCode += `window['litemove_${timeline.id}'].add( Flip.to(state_flip_${item.id}, { targets: origin_flip_${item.id}, duration: ${item.duration}, delay: ${item.delay ? item.delay : 0}, scale: true, ease: '${item.ease ? item.ease : 'none'}', }) ); }`;
							} else {
								flipCode += `window['litemove_${timeline.id}'].add( Flip.from(state_flip_${item.id}, { targets: origin_flip_${item.id}, duration: ${item.duration}, delay: ${item.delay ? item.delay : 0}, scale: true, ease: '${item.ease ? item.ease : 'none'}', }) ); }`;
							}
						} else {
							flipCode += `const origin_flip_${item.id} = document.querySelector('${item.selector}'); const state_flip_${item.id} = Flip.getState('${item.selector}');`;
							if (!item.flipMethod) {
								item.flipMethod = 'addClass';
							}
							switch (item.flipMethod) {
								case 'addClass':
									flipCode += `origin_flip_${item.id}.classList.add('${item.flipClass}');`;
									break;
								case 'removeClass':
									flipCode += `origin_flip_${item.id}.classList.remove('${item.flipClass}');`;
									break;
								case 'toggleClass':
									flipCode += `origin_flip_${item.id}.classList.toggle('${item.flipClass}');`;
									break;
							}
							flipCode += `window['litemove_${timeline.id}'].add( Flip.from(state_flip_${item.id}, { duration: ${item.duration}, delay: ${item.delay ? item.delay : 0}, scale: true, ease: '${item.ease ? item.ease : 'none'}', }) );`;
						}

						code += flipCode;
					}

					const additionOptions:any = {
						duration: item.duration,
						ease: item.ease ? item.ease : 'none',
					};
			
					if (item.staggerEach) {
						additionOptions.stagger = {};
						additionOptions.stagger.each = item.staggerEach;
						if (item.staggerFrom) {
							additionOptions.stagger.from = item.staggerFrom;
						}
					}
			
					if (item.repeat) {
						additionOptions.repeat = item.repeat;
						if (item.repeatDelay) {
							additionOptions.repeatDelay = item.repeatDelay;
						}
					}
			
					if (item.yoyo) {
						additionOptions.yoyo = item.yoyo;
					}

					if (item.transformOrigin) {
						additionOptions.transformOrigin = item.transformOrigin;
					}

					if (!item.flip) {
						if (isEmpty(item.from) && !isEmpty(item.to)) {
							const toObject = litemoveStringify({ ...itemTo, ...additionOptions });
							if (!code.includes('litemove_' + timeline.id + '_st_' + item.id)) {
								code += `window['litemove_${timeline.id}'].to('${selector}', ${toObject}, ${item.delay});`;
							} else {
								code += `window['litemove_${timeline.id}'].to(${selector}, ${toObject}, ${item.delay});`;
							}
						} else if (isEmpty(item.to) && !isEmpty(item.from)) {
							const fromObject = litemoveStringify({ ...itemFrom, ...additionOptions });
							if (!code.includes('litemove_' + timeline.id + '_st_' + item.id)) {
								code += `window['litemove_${timeline.id}'].from('${selector}', ${fromObject}, ${item.delay});`;
							} else {
								code += `window['litemove_${timeline.id}'].from(${selector}, ${fromObject}, ${item.delay});`;
							}
						} else if (!isEmpty(item.from) && !isEmpty(item.to)) {
							const fromObject = litemoveStringify({ ...itemFrom });
							const toObject = litemoveStringify({ ...itemTo, ...additionOptions });
							if (!code.includes('litemove_' + timeline.id + '_st_' + item.id)) {
								code += `window['litemove_${timeline.id}'].fromTo( '${selector}', ${fromObject}, ${toObject}, ${item.delay} );`;
							} else {
								code += `window['litemove_${timeline.id}'].fromTo( ${selector}, ${fromObject}, ${toObject}, ${item.delay} );`;
							}
						}
					}

				} catch (e) {
					console.log(e);
				}
			}
		}

		if (!editor || editor && livePreview) {
			if (timeline.trigger === 'page' && timeline.pageStart === 'contentLoaded' && !editor) {
				code += `});`;
			}
			
			if (!timeline.mobile || !timeline.tablet || !timeline.desktop) {
				code += `}`;
			}
		}
	});

	if (project.settings?.resetTimelinesOnWindowResize || project.settings?.resetTimelinesOnURLChangeNotHash || project.settings?.resetTimelinesOnHashChange) {
		code += `} litemoveCreateTimelines();`;

		const resetTimelines = `clearTimeout(litemoveResizeTimer); litemoveResizeTimer = setTimeout(function () { litemoveCreateTimelines(); }, 250);`;

		if (project.settings?.resetTimelinesOnWindowResize) {
			code += `window.addEventListener("resize", () => { ${resetTimelines} });`;
		}

		if (project.settings?.resetTimelinesOnURLChangeNotHash) {
			code += `document.body.addEventListener('click', () => { if (litemoveCurrentUrl !== location.href.replace(location.hash,"")) { litemoveCurrentUrl = location.href.replace(location.hash,""); ${resetTimelines} } });`;
		}

		if (project.settings?.resetTimelinesOnHashChange) {
			code += `document.body.addEventListener('click', () => { if (location.hash !== '' && litemoveCurrentHash !== location.hash) { litemoveCurrentHash = location.hash; ${resetTimelines} } });`;
		}
	}

	if (!editor) {
		code += `});`;
	}

	if (!editor && !livePreview) {
		code += `</script>`;
	}

	if (project.settings?.customJsAfter) {
		if (!editor) {
			code += `\n<script id="litemove-js-after">${project.settings?.customJsAfter}</script>`;
		} else {
			code += project.settings?.customJsAfter;
		}
	}

	let libsScript = '';
	let registerPlugin:any = [];
	libs = Array.from(new Set(libs));

	libs.map((lib:any) => {
		libsScript += generateSript(scripts[lib]);
		if (scripts[lib].registerPlugin) {
			registerPlugin.push(scripts[lib].registerPlugin);
		}
	});

	if (!editor && registerPlugin.length > 0) {
		code = `<script>gsap.registerPlugin(${registerPlugin.join(', ')});</script>\n` + code;
	}

	return {code: code, libs: libsScript.slice(0, -1) };
}

export const isEmpty = (obj: Object) => {
	if (!obj) {
		return true;
	}
	return Object.keys(obj).length === 0;
};