import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AppContext from '../AppContext/AppContext';
import { Typography, Divider, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import IconGoogle from './icon-google.svg';
import LoginByPassword from './LoginByPassword';
import Register from './Register';
import LoginByGoogle from './LoginGoogle';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Config } from '../../Config';
import './Login.scss';

export default function Login(props: any) {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AppContext);
    const { user } = state;
    const [userInput, setUserInput] = useState({ first_name: '', last_name: '', username: '', email: '', password: '', confirm_password: '' });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState({ singUp: false, signIn: false });
    const [isRegister, setIsRegister] = useState(false);
    const [typeButton, setTypeButton] = useState(true);
    const [passwordError, setPasswordError] = useState({ status: false, msg: '' });

    useEffect(() => {
        if (user) {
            if (user.status == 'active') {
                navigate('/projects/');
            } else {
                navigate('/verify/');
            }
        }
    }, [navigate, user]);

    useEffect(() => {
        setTypeButton(!typeButton);
    }, [isRegister]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordError({ ...passwordError, status: false, msg: '' });
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
    };

    const handleSignUp = () => {
        if (!typeButton) {
            setUserInput({ ...userInput, email: '', password: '', confirm_password: '' });
            setIsRegister(!isRegister);
        }
    }

    const handSignIn = () => {
        if (typeButton) {
            setIsRegister(!isRegister);
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isRegister) {
            setLoading({ ...loading, singUp: true });
            if (userInput.password !== userInput.confirm_password) {
                setPasswordError({ ...passwordError, status: true, msg: "Password don't match!" });
                setLoading({ ...loading, singUp: false });
                return;
            }
            try {
                const option = {
                    method: 'POST',
                    url: '/api/v1/auth/register',
                    data: userInput,
                };
                const res = await axios(option);
                const { token, user } = res.data.data;
                localStorage.setItem('token', token);
                dispatch({ type: 'CURRENT_USER', payload: user });
                setError(null);
                if (localStorage.getItem('redirect')) {
                    navigate(localStorage.getItem('redirect') || '/');
                    localStorage.removeItem('redirect');
                } else {
                    navigate('/projects/');
                }
            } catch (error: any) {
                setError(error.response.data.error);
            } finally {
                setLoading({ ...loading, singUp: false });
            }
        } else {
            setLoading({ ...loading, signIn: true });
            try {
                const option = {
                    method: 'POST',
                    url: '/api/v1/auth/login',
                    data: userInput,
                };
                const res = await axios(option);
                const { token, user } = res.data.data;
                localStorage.setItem('token', token);
                dispatch({ type: 'CURRENT_USER', payload: user });
                setError(null);
                if (localStorage.getItem('redirect')) {
                    navigate(localStorage.getItem('redirect') || '/');
                    localStorage.removeItem('redirect');
                } else {
                    navigate('/');
                }
            } catch (error: any) {
                setError(error.response.data.error);
            } finally {
                setLoading({ ...loading, signIn: false });
            }
        }
    };

    return (
        <>
            <div className="login">
                <form className="login__form" onSubmit={handleSubmit}>
                    <h1 className="login__title">Welcome</h1>

                    <Stack direction="row" spacing={2} color="#3c6df1">
                        <GoogleOAuthProvider clientId={Config.GOOGLE_CLIENT_ID}>
                            <LoginByGoogle />
                        </GoogleOAuthProvider>
                    </Stack>

                    <Divider sx={{ my: 3 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            OR
                        </Typography>
                    </Divider>

                    {isRegister ? <Register change={handleChange} setVal={userInput} error={passwordError.status} errorMsg={passwordError.msg} /> : <LoginByPassword change={handleChange} />}

                    {error && (
                        <div className="login__error">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
                            </svg>
                            <span>{error}</span>
                        </div>
                    )}

                    <Stack direction="row" spacing={2} color="#3c6df1">
                        <LoadingButton
                            loading={loading.singUp}
                            size="large"
                            fullWidth
                            type={typeButton ? "submit" : "button"}
                            variant={typeButton ? 'contained' : 'outlined'}
                            onClick={handleSignUp}
                        >
                            {isRegister ? 'Create Account' : 'Sign Up'}
                        </LoadingButton>

                        <LoadingButton
                            loading={loading.signIn}
                            size="large"
                            fullWidth
                            type={typeButton ? "button" : "submit"}
                            variant={typeButton ? 'outlined' : 'contained'}
                            onClick={handSignIn}
                        >
                            {isRegister ? 'Cancel' : 'Sign In'}
                        </LoadingButton>
                    </Stack>
                </form>
            </div>
        </>
    );
}
