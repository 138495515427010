import React, { useContext, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-tagsinput/react-tagsinput.css';
import TagsInput from 'react-tagsinput';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Iconify from '../Iconify/Iconify';
import EditorContext from '../EditorContext/EditorContext';

function ControlAnimation(props: any) {
  const {
    timelines,
    setTimelines,
    timeline,
    setTimeline,
    animationCurrent,
    setAnimationCurrent,
    cssSelector,
    setCssSelector,
    removedSelector,
    setRemovedSelector,
  } = useContext(EditorContext);

  const handleChangeAnimationSetting = (e: any, controlType?: string) => {
    const timelinesNew = timelines.map((item: any) => {
      if (item.id === timeline.id) {
        let animationsNew = item.animations.map((animationItem: any) => {
          if (animationItem.id === animationCurrent.id) {
            const controlId = controlType === 'select' ? e.target.name : e.target.attributes?.id?.value;
            let fieldValue: any;

            switch (controlType) {
              case 'checkbox':
                fieldValue = !(animationItem[controlId]) ? true : false;
                break;
        
              case 'number':
                fieldValue = e.target.valueAsNumber;
                break;
        
              default:
                fieldValue = e.target.value;
                break;
            }

            if (typeof fieldValue === 'string' && fieldValue.trim() === '') {
              delete animationItem[controlId];
            } else {
              animationItem[controlId] = fieldValue;
            }
          }

          return animationItem;
        });
        setTimeline({
          ...item,
          animations: animationsNew,
        });
        return {
          ...item,
          animations: animationsNew,
        };
      } else {
        return item;
      }
    });

    setTimelines(timelinesNew);
  };

  const handleChangeAnimationSelector = (value: string[]) => {
    const timelinesNew = timelines.map((item: any) => {
      if (item.id === timeline.id) {
        let animationsNew = item.animations.map((animationItem: any) => {
          if (animationItem.id === animationCurrent.id) {
            if (animationItem.selector.trim() !== '') {
              if (value.length === 0) {
                setRemovedSelector(animationItem.selector);
              } else {
                if (value.length < animationItem.selector.split(',').length) {
                  const selectorRemoved = animationItem.selector.split(',').filter((x: any) => !value.includes(x));
                  setRemovedSelector(selectorRemoved[0]);
                }
              }
            }
            const uniqueSelectors = Array.from(new Set(value));
            const uniqueSelectorsNew = uniqueSelectors.filter((selector: any) => {
              if (selector.trim() !== '') {
                try {
                  document.querySelector(selector);
                  return selector;
                } catch (err) {
                }
              }
            });
            
            animationItem.selector = uniqueSelectorsNew.join(',');
          }
          return animationItem;
        });
        setTimeline({
          ...item,
          animations: animationsNew,
        });
        return {
          ...item,
          animations: animationsNew,
        };
      } else {
        return item;
      }
    });

    setTimelines(timelinesNew);
  };

  const handleChangeAnimationAttr = (e: any, controlType?: string) => {
    const timelinesNew = timelines.map((item: any) => {
      if (item.id === timeline.id) {
        let animationsNew = item.animations.map((animationItem: any) => {
          if (animationItem.id === animationCurrent.id) {
            const fieldValue = e.target.value;
            if (!animationItem[fromTo]) {
              animationItem[fromTo] = {};
            }
            if (fieldValue.trim() === '') {
              delete animationItem[fromTo][e.target.attributes.id.value];
            } else {
              animationItem[fromTo][e.target.attributes.id.value] = fieldValue;
            }
          }
          return animationItem;
        });
        setTimeline({
          ...item,
          animations: animationsNew,
        });
        return {
          ...item,
          animations: animationsNew,
        };
      } else {
        return item;
      }
    });

    setTimelines(timelinesNew);
  };

  const [fromTo, setFromTo] = useState<string>('from');

  const handleChangeFromTo = (e: any) => {
    setFromTo(e.target.value);
  };

  return (
    <div className="controls">
      <div
        className="controls__header"
        onClick={(e) => {
          const tar = e.target as HTMLElement;
          tar.closest('.controls')?.classList.toggle('controls--collapsed');
        }}
      >
        <div className="controls__header-label">Animation</div>
        <div className="controls__header-icon">
          <Iconify icon="material-symbols:keyboard-arrow-down" />
        </div>
      </div>

      <div className="controls__body">
        {!animationCurrent.id && (
          <div>Please select an animation from the timeline</div>
        )}
        {animationCurrent && animationCurrent.id && (
          <>
          <div className="controls__field-group controls__field-group--selector controls__field-group--label-block">
            <label className='label-flex' htmlFor="selector">
              <span>{animationCurrent.flip ? 'Target Position Selector' : 'Selector'}</span>
              <div
                className={
                  cssSelector.enable && cssSelector.controlId === 'selector' && cssSelector.type === 'animation'
                    ? 'selector-target active'
                    : 'selector-target'
                }
                onClick={() => {
                  const enableCssSelector = !cssSelector.enable;
                  setCssSelector({ enable: enableCssSelector, controlId: 'selector', type: 'animation', multiple: true });
                }}
              >
                <Iconify icon="fluent:resize-small-16-regular" />
              </div>
            </label>
            <TagsInput
              value={animationCurrent.selector === '' ? [] : animationCurrent.selector.split(',')}
              onChange={(value) => {
                handleChangeAnimationSelector(value);
              }}
              inputProps={{
                placeholder: 'Enter selector',
              }}
            />
          </div>

          <div className="controls__field-group">
            <label htmlFor="flip">
              <span>Enable Flip</span>
              <Tooltip title="The element will be animated from it's current position to the new selector position" arrow placement="top">
                <div className='control-description'><Iconify icon="ph:info" /></div>
              </Tooltip>
            </label>
            <Switch
              checked={animationCurrent.flip ? true : false}
              onChange={(e) => {
                handleChangeAnimationSetting(e, 'checkbox');
              }}
              id='flip'
            />
          </div>

          {animationCurrent.flip && (
            <>
              <div className="controls__field-group controls__field-group--label-block">
                <label htmlFor="flipMethod">Flip Method</label>
                <Select
                  id="flipMethod"
                  name='flipMethod'
                  value={animationCurrent.flipMethod ? animationCurrent.flipMethod : 'addClass'}
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'select');
                  }}
                  IconComponent={() => (
                    <div className="control-select-arrow">
                      <Iconify icon="material-symbols:keyboard-arrow-down" />
                    </div>
                  )}
                >
                  <MenuItem value="addClass" style={{fontSize: '12px'}}>Add Class</MenuItem>
                  <MenuItem value="removeClass" style={{fontSize: '12px'}}>Remove Class</MenuItem>
                  <MenuItem value="toggleClass" style={{fontSize: '12px'}}>Toggle Class</MenuItem>
                  <MenuItem value="moveToTarget" style={{fontSize: '12px'}}>Move to Origin Position</MenuItem>
                  <MenuItem value="moveToOrigin" style={{fontSize: '12px'}}>Move to Target Position</MenuItem>
                </Select>
              </div>
              
              {(['addClass', 'removeClass', 'toggleClass'].includes(animationCurrent.flipMethod) || !animationCurrent.flipMethod) && (
                <div className="controls__field-group controls__field-group--label-block">
                  <label htmlFor="flipClass">
                    Class (without dot)
                  </label>
                  <TextField
                    id="flipClass"
                    type='text'
                    value={animationCurrent.flipClass ? animationCurrent.flipClass : ''}
                    fullWidth
                    onChange={(e) => {
                      handleChangeAnimationSetting(e, 'text');
                    }}
                  />
                </div>
              )}

              {['moveToTarget', 'moveToOrigin'].includes(animationCurrent.flipMethod) && (
                <div className="controls__field-group controls__field-group--selector controls__field-group--label-block">
                  <label className='label-flex' htmlFor="flipTargetSelector">
                    <span>Origin Position Selector</span>
                    <div
                      className={
                        cssSelector.enable && cssSelector.controlId === 'flipTargetSelector' && cssSelector.type === 'animation'
                          ? 'selector-target active'
                          : 'selector-target'
                      }
                      onClick={() => {
                        const enableCssSelector = !cssSelector.enable;
                        setCssSelector({ enable: enableCssSelector, controlId: 'flipTargetSelector', type: 'animation' });
                      }}
                    >
                      <Iconify icon="fluent:resize-small-16-regular" />
                    </div>
                  </label>
                  <TextField
                    value={animationCurrent.flipTargetSelector}
                    id='flipTargetSelector'
                    onChange={(e) => {
                      handleChangeAnimationSetting(e, 'text');
                    }}
                    placeholder='Enter selector'
                  />
                  <div className='invalid-message'>Invalid Selector</div>
                </div>
              )}
            </>
          )}

          <div className="controls__field-group">
            <label htmlFor="delay">Delay (s)</label>
            <TextField
              id="delay"
              value={animationCurrent.delay}
              fullWidth
              type='number'
              inputProps={{
                step: 0.1,
                lang:"en-US"
              }}
              onChange={(e) => {
                handleChangeAnimationSetting(e, 'number');
              }}
            />
          </div>

          <div className="controls__field-group">
            <label htmlFor="duration">Duration (s)</label>
            <TextField
              id="duration"
              value={animationCurrent.duration}
              fullWidth
              type='number'
              inputProps={{
                step: 0.1,
                lang:"en-US"
              }}
              onChange={(e) => {
                handleChangeAnimationSetting(e, 'number');
              }}
            />
          </div>
          
          {!animationCurrent.flip && (
            <>
              <div className="controls__field-group controls__field-group--label-block">
                <RadioGroup
                  row
                  className='radio-group'
                  id="fromTo"
                  value={fromTo}
                  onChange={handleChangeFromTo}
                >
                  <FormControlLabel value="from" control={<Radio id="fromTo" />} label="From" />
                  <FormControlLabel value="to" control={<Radio id="fromTo" />} label="To" />
                </RadioGroup>
              </div>

              <div className="controls__field-group">
                <label htmlFor="x">Translate X</label>
                <TextField
                  id="x"
                  value={animationCurrent[fromTo]?.x ? animationCurrent[fromTo].x : ''}
                  fullWidth
                  onChange={handleChangeAnimationAttr}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="y">Translate Y</label>
                <TextField
                  id="y"
                  value={animationCurrent[fromTo]?.y ? animationCurrent[fromTo].y : ''}
                  fullWidth
                  onChange={handleChangeAnimationAttr}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="opacity">Opacity</label>
                <TextField
                  id="opacity"
                  value={animationCurrent[fromTo]?.opacity ? animationCurrent[fromTo].opacity : ''}
                  fullWidth
                  onChange={handleChangeAnimationAttr}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="scale">Scale</label>
                <TextField
                  id="scale"
                  value={animationCurrent[fromTo]?.scale ? animationCurrent[fromTo].scale : ''}
                  fullWidth
                  onChange={handleChangeAnimationAttr}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="rotation">Rotation</label>
                <TextField
                  id="rotation"
                  value={animationCurrent[fromTo]?.rotation ? animationCurrent[fromTo].rotation : ''}
                  fullWidth
                  onChange={handleChangeAnimationAttr}
                />
              </div>

              <div className="controls__field-group controls__field-group--label-block">
                <label htmlFor="custom">Custom</label>
                <div className='code-wrapper'>
                  <CodeEditor
                    id="custom"
                    value={animationCurrent[fromTo]?.custom ? animationCurrent[fromTo].custom : ''}
                    onChange={handleChangeAnimationAttr}
                    language="js"
                    placeholder='propertyFirst: "value",&#10;propertySecond: "value2",&#10;propertyThird: function() { return "value3" }'
                    data-color-mode="dark"
                  />
                </div>
              </div>
          
              <div className="separator"></div>

              <div className="controls__field-group">
                <label htmlFor="splitText">
                  <span>Enable Split Text</span>
                </label>
                <Switch
                  checked={animationCurrent.splitText ? true : false}
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'checkbox');
                  }}
                  id='splitText'
                />
              </div>

              {animationCurrent.splitText && (
                <>
                  <div className="controls__field-group">
                    <label htmlFor="splitTextType">Split Type</label>
                    <Select
                      id="splitTextType"
                      name='splitTextType'
                      value={animationCurrent.splitTextType ? animationCurrent.splitTextType : 'chars,words'}
                      onChange={(e) => {
                        handleChangeAnimationSetting(e, 'select');
                      }}
                      IconComponent={() => (
                        <div className="control-select-arrow">
                          <Iconify icon="material-symbols:keyboard-arrow-down" />
                        </div>
                      )}
                    >
                      <MenuItem value="chars" style={{fontSize: '12px'}}>Chars</MenuItem>
                      <MenuItem value="words" style={{fontSize: '12px'}}>Words</MenuItem>
                      <MenuItem value="lines" style={{fontSize: '12px'}}>Lines</MenuItem>
                    </Select>
                  </div>
                  <div className="controls__field-group">
                    <label htmlFor="splitTextClass">Split Class</label>
                    <TextField
                      id="splitTextClass"
                      value={animationCurrent.splitTextClass ? animationCurrent.splitTextClass : ''}
                      onChange={(e) => {
                        handleChangeAnimationSetting(e, 'text');
                      }}
                    />
                  </div>
                </>
              )}

              <div className="controls__field-group">
                <label htmlFor="staggerEach">Stagger Each (s)</label>
                <TextField
                  id="staggerEach"
                  type='number'
                  inputProps={{
                    step: 0.1,
                    lang:"en-US"
                  }}
                  value={animationCurrent.staggerEach ? animationCurrent.staggerEach : ''}
                  fullWidth
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'number');
                  }}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="staggerFrom">Stagger From</label>
                <Select
                  id="staggerFrom"
                  name='staggerFrom'
                  value={animationCurrent.staggerFrom ? animationCurrent.staggerFrom : 'start'}
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'select');
                  }}
                  IconComponent={() => (
                    <div className="control-select-arrow">
                      <Iconify icon="material-symbols:keyboard-arrow-down" />
                    </div>
                  )}
                >
                  <MenuItem value="start" style={{fontSize: '12px'}}>start</MenuItem>
                  <MenuItem value="center" style={{fontSize: '12px'}}>center</MenuItem>
                  <MenuItem value="edges" style={{fontSize: '12px'}}>edges</MenuItem>
                  <MenuItem value="random" style={{fontSize: '12px'}}>random</MenuItem>
                  <MenuItem value="end" style={{fontSize: '12px'}}>end</MenuItem>
                </Select>
              </div>

              <div className="controls__field-group">
                <label htmlFor="repeat">Repeat (times)</label>
                <TextField
                  id="repeat"
                  type='number'
                  inputProps={{
                    min: -1,
                    lang:"en-US"
                  }}
                  value={animationCurrent.repeat ? animationCurrent.repeat : ''}
                  fullWidth
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'number');
                  }}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="repeatDelay">Repeat Delay (s)</label>
                <TextField
                  id="repeatDelay"
                  type='number'
                  inputProps={{
                    step: 0.1,
                    min: 0,
                    lang:"en-US"
                  }}
                  value={animationCurrent.repeatDelay ? animationCurrent.repeatDelay : ''}
                  fullWidth
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'number');
                  }}
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="yoyo">
                  <span>Enable Yoyo</span>
                </label>
                <Switch
                  checked={animationCurrent.yoyo ? true : false}
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'checkbox');
                  }}
                  id='yoyo'
                />
              </div>

              <div className="controls__field-group">
                <label htmlFor="transformOrigin">
                  Transform Origin
                  <Tooltip title='Click to learn more' arrow placement="top">
                    <a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/CSS/transform-origin">
                      <div className='control-description'><Iconify icon="ph:info" /></div>
                    </a>
                  </Tooltip>
                </label>
                <TextField
                  id="transformOrigin"
                  type='text'
                  placeholder='50% 50% 0'
                  value={animationCurrent.transformOrigin ? animationCurrent.transformOrigin : ''}
                  fullWidth
                  onChange={(e) => {
                    handleChangeAnimationSetting(e, 'text');
                  }}
                />
              </div>
            </>
          )}

          <div className="controls__field-group">
            <label htmlFor="ease">Ease</label>
            <Select
              id="ease"
              name='ease'
              value={animationCurrent.ease ? animationCurrent.ease : 'none'}
              onChange={(e) => {
                handleChangeAnimationSetting(e, 'select');
              }}
              IconComponent={() => (
                <div className="control-select-arrow">
                  <Iconify icon="material-symbols:keyboard-arrow-down" />
                </div>
              )}
            >
              <MenuItem value="none" style={{fontSize: '12px'}}>None</MenuItem>
              <MenuItem value="power1" style={{fontSize: '12px'}}>Power 1</MenuItem>
              <MenuItem value="power2" style={{fontSize: '12px'}}>Power 2</MenuItem>
              <MenuItem value="power3" style={{fontSize: '12px'}}>Power 3</MenuItem>
              <MenuItem value="power4" style={{fontSize: '12px'}}>Power 4</MenuItem>
              <MenuItem value="back" style={{fontSize: '12px'}}>Back</MenuItem>
              <MenuItem value="elastic" style={{fontSize: '12px'}}>Elastic</MenuItem>
              <MenuItem value="bounce" style={{fontSize: '12px'}}>Bounce</MenuItem>
              <MenuItem value="rough" style={{fontSize: '12px'}}>Rough</MenuItem>
              <MenuItem value="slow" style={{fontSize: '12px'}}>Slow</MenuItem>
              <MenuItem value="circ" style={{fontSize: '12px'}}>Circ</MenuItem>
              <MenuItem value="expo" style={{fontSize: '12px'}}>Expo</MenuItem>
              <MenuItem value="sine" style={{fontSize: '12px'}}>Sine</MenuItem>
            </Select>
          </div>
          </>
        )}
        </div>
    </div>
  );
}

export default ControlAnimation;
