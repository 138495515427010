import React, { useContext, useState, useRef, useEffect } from 'react';
import Animation from '../Animation/Animation';
import EditorContext from '../EditorContext/EditorContext';
import Iconify from '../Iconify/Iconify';
import { IconButton, Tooltip } from '@mui/material';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { ScrollSmoother } from 'gsap/all';
import { TextField } from '@mui/material';
import { Rnd } from 'react-rnd';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { createGsapCode, isEmpty } from '../../Utils';
import { Config } from '../../Config';
import './Timeline.scss';
import { stringify } from 'querystring';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText);

function Timeline(props: any) {
  const {
    timelines,
    setTimelines,
    timeline,
    setTimeline,
    timelineChanged,
    setTimelineChanged,
    animationCurrent,
    setAnimationCurrent,
    iframeRef,
    setIframeLoaded,
    iframeLoaded,
    cssSelector,
    setCssSelector,
    removedSelector,
    setRemovedSelector,
    device,
    setDevice,
    repeatTimeline,
    setRepeatTimeline,
    fullScreen,
    livePreview,
    setLivePreview,
    setFullScreen,
    page,
    editorTab,
    setEditorTab,
    project,
  } = useContext(EditorContext);
  const [play, setPlay] = useState<boolean>(false);
  const [progressTl, setProgressTl] = useState<any>(0);
  const [durationTl, setDurationTl] = useState<number>(0);
  const [completeTl, setCompleteTl] = useState<boolean>(true);
  const [settingsChanged, setSettingsChanged] = useState<boolean>(false);
  const [progressTime, setProgressTime] = useState<number>(0);
  const [times, setTimes] = useState<any>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const [animationRightClicked, setAnimationRightClicked] = useState<any>({}); // {animationId: '', x: 0, y: 0}

  const addAnimation = (animationAdd: any) => {
    const timelinesNew = timelines.map((item: any) => {
      if (item.id === timeline.id) {
        const animationNew:any = animationAdd
          ? {...animationAdd, from: {...animationAdd.from}, to : {...animationAdd.to}}
          : {
              id: Math.random().toString(36).substr(2, 9),
              selector: '',
              delay: 0,
              duration: 0.2,
              from: {},
              to: {},
            };

        setAnimationCurrent(animationNew);
        setEditorTab('animation');

        const timelineNew = {
          ...item,
          animations: [...item.animations, animationNew],
        };

        setTimeline(timelineNew);

        return timelineNew;
      }
      return item;
    });

    setCssSelector({ enable: false, animationId: '' });
    setTimelines(timelinesNew);
  };

  const deleteAnimation = (animationId: Object) => {
    const timelinesNew = timelines.map((item: any) => {
      if (item.id === timeline.id) {
        const animationsNew = item.animations.filter((animationItem: any) => animationItem.id !== animationId);

        const timelineNew = {
          ...item,
          animations: animationsNew,
        };

        setTimeline(timelineNew);

        return timelineNew;
      }
      return item;
    });

    setAnimationCurrent({});
    setCssSelector({ enable: false, animationId: '' });
    setTimelines(timelinesNew);
  };

  const gsapTl = useRef<any>();

  useEffect(() => {
    gsapTl.current = gsap.timeline({
      paused: true,
      ease: "none",
      repeat: repeatTimeline ? -1 : 0,
      onComplete: () => {
        if(!repeatTimeline) {
          setCompleteTl(true);
          setPlay(false);
        }
      },
      onUpdate: () => {
        const time = gsapTl.current?.time().toFixed(2);
        setProgressTime(time);
      },
    });
  }, [timeline, repeatTimeline]);

  const recreateTl = (pause?: boolean) => {
    const iframe = iframeRef.current;
    if (iframe && iframeLoaded) {
      const iframeWindow = iframe.contentWindow;
      
      if(timeline.id && !livePreview) {
        if (!repeatTimeline && iframeWindow['litemove_' + timeline.id]) {
          iframeWindow.eval(`
            window['litemove_${timeline.id}'].seek(0).clear();
          `);
          iframeWindow.eval(`
            delete window['litemove_${timeline.id}'];
          `);
        }

        iframeWindow.eval(`
          let allScrollTrigger = ScrollTrigger.getAll()
          for (let i = 0; i < allScrollTrigger.length; i++) {
            allScrollTrigger[i].kill(true)
          }
        `);
      }

      timelines.forEach((item: any) => {
        const timelineItem = item;
        for (let i = 0; i < timelineItem?.animations?.length; i++) {
          const item = timelineItem?.animations[i];
          if (timelineChanged && item.selector || !timelineChanged && timeline.id === timelineItem.id && item.selector ) {
            iframeWindow.litemoveResetStyle(item.selector);
          }
          if (iframeWindow['litemove_' + timelineItem.id + '_st_' + item.id]) {
            iframeWindow['litemove_' + timelineItem.id + '_st_' + item.id].revert();
          }
        }

        const timelineSettings:any = {
          ease: "none",
        }

        if (pause) {
          timelineSettings.paused = true;
        }

        if(timeline.id === timelineItem.id && !livePreview && repeatTimeline) {
          timelineSettings.repeat = -1;
          timelineSettings.paused = true;
        }

        if(timelineItem.trigger === 'click' || timelineItem.trigger === 'hover') {
          timelineSettings.paused = true;
        }

        iframeWindow.eval(`
          window['litemove_${timelineItem.id}'] = gsap.timeline(${JSON.stringify(timelineSettings)});
        `);
      });

      if(timeline.id) {
        const gsapCode = createGsapCode(project, timelineChanged || livePreview ? timelines : [timeline], true, livePreview) ? createGsapCode(project, timelineChanged || livePreview ? timelines : [timeline], true, livePreview).code : '';
        try {
          iframe.contentWindow.eval(gsapCode);
        } catch (error) {
          console.log(error);
        }
      }

      if(iframe.contentWindow['litemove_' + timeline.id]) {
        const duration = iframe.contentWindow['litemove_' + timeline.id].duration();
        setDurationTl(duration);
        gsapTl.current.seek(0).clear();
        gsapTl.current.to(
          '.litemove-invisible',
          { duration: duration, x: duration * 250, ease: 'none' }
        );
      }

      setTimelineChanged(false);
    }
  };

  useEffect(() => {
    recreateTl();
    setPlay(false);
  }, [livePreview]);

  useEffect(() => {
    recreateTl();
    setPlay(false);
  }, [repeatTimeline]);

  const handlePlayTl = () => {
    const iframe = iframeRef.current;
    if (livePreview) {
      setLivePreview(false);
    }
    setPlay(!play);
    if (!play && completeTl && settingsChanged) {
      recreateTl();
    }
    setCompleteTl(false);
  };

  useEffect(() => {
    if (removedSelector !== '') {
      const iframe = iframeRef.current;
      const iframeDocument = iframe.contentDocument;
      iframe.contentWindow.litemoveResetStyle(removedSelector);
    }
    recreateTl(true);
    setSettingsChanged(true);
    setProgressTime(0);
    setRemovedSelector('');
  }, [timelines, timeline]);

  useEffect(() => {
    recreateTl();
    setProgressTime(0);
  }, [timelineChanged]);

  useEffect(() => {
    if (iframeLoaded) {
      setTimelineChanged(true);
    }
  }, [iframeLoaded]);

  useEffect(() => {
    gsapTl.current?.pause();
    gsapTl.current?.progress(progressTl);
    const iframe = iframeRef.current;
    if(iframe) {
      iframe.contentWindow['litemove_' + timeline.id]?.pause();
      iframe.contentWindow['litemove_' + timeline.id]?.progress(progressTl);
    }
  }, [progressTl]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (play) {
      iframe?.contentWindow['litemove_' + timeline.id]?.play();
      gsapTl.current?.play();
    } else {
      iframe?.contentWindow['litemove_' + timeline.id]?.pause();
      gsapTl.current?.pause();
    }
  }, [play]);

  const resizeDirections = {
    bottom: false,
    bottomLeft: false,
    bottomRight: false,
    left: true,
    right: true,
    top: false,
    topLeft: false,
    topRight: false,
  };

  const renderThumb = () => {
    return <div style={{ background: '#767676', borderRadius: '100px' }} />;
  };

  // Remove context menu
  useEffect(() => {
    const handleClick = () => {
      setAnimationRightClicked({ animationId: '', x: 0, y: 0 });
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  let timelineRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    timelineRef.current?.addEventListener('mouseover', (e: any) => {
      const iframe = iframeRef.current;
      const iframeWindow = iframe?.contentWindow;
      iframeWindow.litemoveSelectorRect({hide: true});
    });
  }, [iframeRef]);

  return (
    <div className="timeline" ref={timelineRef}>
      <div className="timeline__bar">
        <div className="timeline__bar-left">
          <div className="timeline__bar-buttons">
            <div className="timeline__bar-button timeline__bar-button--add">
              <Tooltip title="Add animation" arrow placement="top">
                <div className="timeline__bar-button-inner" onClick={() => addAnimation(null)}>
                  <Iconify icon="material-symbols:add" />
                </div>
              </Tooltip>
            </div>
            <div className="timeline__bar-button timeline__bar-button--play">
              <Tooltip title={play ? 'Pause' : 'Play'} arrow placement="top">
                <div
                  className="timeline__bar-button-inner"
                  onClick={() => {
                    handlePlayTl();
                  }}
                >
                  <Iconify icon={play ? 'ph:pause-light' : 'ph:play-light'} />
                </div>
              </Tooltip>
            </div>
            <div className="timeline__bar-button timeline__bar-button--repeat">
              <Tooltip title="Repeat Timeline" arrow placement="top">
                <div
                  className={repeatTimeline ? 'timeline__bar-button-inner active' : 'timeline__bar-button-inner'}
                  onClick={() => {
                    setRepeatTimeline(!repeatTimeline);
                  }}
                >
                  <Iconify icon="ph:repeat" />
                </div>
              </Tooltip>
            </div>
            <div className="timeline__bar-button timeline__bar-button--fullscreen">
              <Tooltip title="Full Screen" arrow placement="top">
                <div
                  className={fullScreen ? 'timeline__bar-button-inner active' : 'timeline__bar-button-inner'}
                  onClick={() => {
                    setFullScreen(!fullScreen);
                  }}
                >
                  <Iconify icon="fluent:full-screen-maximize-16-regular" />
                </div>
              </Tooltip>
            </div>
            <div className="timeline__bar-button timeline__bar-button--reload">
              <Tooltip title="Reload page" arrow placement="top">
                <div
                  className='timeline__bar-button-inner'
                  onClick={() => {
                    const iframe = iframeRef.current;
                    if (iframe && iframeLoaded) {
                      iframe.contentWindow.location.reload();
                      setIframeLoaded(false);
                    }
                  }}
                >
                  <Iconify icon="ant-design:reload-outlined" />
                </div>
              </Tooltip>
            </div>
            <div className="timeline__bar-button timeline__bar-button--text">
              <div
                className={livePreview ? 'timeline__bar-button-inner active' : 'timeline__bar-button-inner'}
                onClick={() => {
                  setLivePreview(!livePreview);
                }}
              >
                Live Preview
              </div>
            </div>
            <div className="timeline__bar-button timeline__bar-button--view">
              <Tooltip title="View Frontend (Please save timeline before view)" arrow placement="top">
                <a
                  href={`${Config.PUBLIC_URL}/api/v1/html/${page.html}/preview`}
                  target="_blank"
                  rel="noreferrer"
                  className='timeline__bar-button-inner'
                  onClick={(e) => {
                    const date = new Date();
                    e.preventDefault();
                    window.open(`${Config.PUBLIC_URL}/api/v1/html/${page.html}/preview?time=` + date.getTime(), '_blank');
                  }}
                >
                  <Iconify icon="ph:eye" />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="timeline__bar-center">
          <div className="timeline__bar-buttons">
            <div className="timeline__bar-button">
              <Tooltip title="Mobile" arrow>
                <div
                  className={device === 'mobile' ? 'timeline__bar-button-inner active' : 'timeline__bar-button-inner'}
                  onClick={() => {
                    setDevice('mobile');
                  }}
                >
                  <Iconify icon="ph:device-mobile-light" />
                </div>
              </Tooltip>
            </div>
            <div className="timeline__bar-button">
              <Tooltip title="Tablet" arrow>
                <div
                  className={device === 'tablet' ? 'timeline__bar-button-inner active' : 'timeline__bar-button-inner'}
                  onClick={() => {
                    setDevice('tablet');
                  }}
                >
                  <Iconify icon="ph:device-tablet-light" />
                </div>
              </Tooltip>
            </div>
            <div className="timeline__bar-button">
              <Tooltip title="Desktop" arrow>
                <div
                  className={device === 'desktop' ? 'timeline__bar-button-inner active' : 'timeline__bar-button-inner'}
                  onClick={() => {
                    setDevice('desktop');
                  }}
                >
                  <Iconify icon="ph:desktop-light" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="timeline__bar-right">
          <a className="timeline__bar-logo" style={{width: '60px'}} href="https://softlite.io/litemove/" target="_blank">
            <svg width="100%" height="100%" viewBox="0 0 501 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42.4739 67.5714H0.668457V1.5871H9.52789V59.7271H42.4739V67.5714Z" fill="white"/>
              <path d="M67.5162 67.5714H58.6567V1.5871H67.5162V67.5714Z" fill="white"/>
              <path d="M114.106 67.5714H105.246V8.41624H114.106V67.5714ZM135.701 9.3391H83.7437V1.5871H135.701V9.3391Z" fill="white"/>
              <path d="M196.317 67.5714H151.743V1.5871H196.317V9.24681H160.602V59.9117H196.317V67.5714ZM194.563 37.7631H159.679V30.1034H194.563V37.7631Z" fill="white"/>
              <path d="M251.079 61.4805H244.435L218.133 6.57053L221.917 6.47824V67.5714H213.334V1.5871H224.316L250.156 55.8511H245.542L271.474 1.5871H282.18V67.5714H273.597V6.47824L277.381 6.57053L251.079 61.4805Z" fill="white"/>
              <path d="M334.713 69.048C329.73 69.048 325.146 68.1866 320.963 66.464C316.779 64.7413 313.118 62.3419 309.981 59.2657C306.843 56.128 304.413 52.4673 302.69 48.2837C300.967 44.0385 300.106 39.455 300.106 34.5331C300.106 29.6112 300.967 25.0584 302.69 20.8748C304.413 16.6912 306.843 13.0613 309.981 9.9851C313.118 6.84739 316.779 4.4172 320.963 2.69453C325.146 0.971866 329.73 0.110535 334.713 0.110535C339.697 0.110535 344.28 0.971866 348.464 2.69453C352.647 4.4172 356.308 6.84739 359.446 9.9851C362.583 13.0613 365.014 16.6912 366.736 20.8748C368.459 25.0584 369.32 29.6112 369.32 34.5331C369.32 39.455 368.459 44.0385 366.736 48.2837C365.014 52.4673 362.583 56.128 359.446 59.2657C356.308 62.3419 352.647 64.7413 348.464 66.464C344.28 68.1866 339.697 69.048 334.713 69.048ZM334.713 61.1114C339.697 61.1114 344.096 59.9732 347.91 57.6968C351.724 55.4204 354.678 52.2827 356.769 48.2837C358.923 44.2231 359.999 39.6396 359.999 34.5331C359.999 29.4266 358.923 24.8739 356.769 20.8748C354.678 16.8758 351.724 13.7381 347.91 11.4617C344.096 9.18529 339.697 8.0471 334.713 8.0471C329.791 8.0471 325.392 9.18529 321.516 11.4617C317.702 13.7381 314.718 16.8758 312.565 20.8748C310.473 24.8739 309.427 29.4266 309.427 34.5331C309.427 39.6396 310.473 44.2231 312.565 48.2837C314.718 52.2827 317.702 55.4204 321.516 57.6968C325.392 59.9732 329.791 61.1114 334.713 61.1114Z" fill="white"/>
              <path d="M413.941 67.5714H404.62L378.872 1.5871H388.47L410.987 61.7574H407.85L430.46 1.5871H439.688L413.941 67.5714Z" fill="white"/>
              <rect x="457.046" y="1.46051" width="43.7143" height="7.28571" fill="white"/>
              <rect x="444.904" y="28.1747" width="43.7143" height="7.28571" fill="white"/>
              <rect x="457.046" y="57.3176" width="43.7143" height="7.28571" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
      <div className="timeline__content">
        <div className="timeline__content-inner">
          <div className="timeline__bar-progress">
            <div className="timeline__bar-progress-time">
              {times.map((item: any, index: number) => {
                return (
                  <div className="timeline__bar-progress-time-item" key={index}>
                    {index === 0 && (
                      <span className="timeline__bar-progress-time-item-text timeline__bar-progress-time-item-text--zero">
                        0s
                      </span>
                    )}
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-split"></span>
                    <span className="timeline__bar-progress-time-item-text">{item}s</span>
                  </div>
                );
              })}
            </div>
          </div>
          <Rnd
            className="timeline__bar-progress-thumb-wrapper"
            id="progress"
            dragAxis="x"
            bounds="parent"
            enableResizing={false}
            default={{
              x: 0,
              y: 0,
              width: 10,
              height: 30,
            }}
            position={{ x: progressTime * 250, y: 0 }}
            onDrag={(e, d) => {
              const percent = d.x / 250 / durationTl;
              setProgressTime(d.x / 250);
              setProgressTl(percent);
            }}
            onClick={() => {}}
          >
            <div className="timeline__bar-progress-thumb"></div>
          </Rnd>
          <Scrollbars
            style={{ height: `145px` }}
            autoHide
            renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
          >
            <div className="timeline__animations">
              {timeline?.animations?.map((item: any, index: any) => (
                <Animation
                  animation={item}
                  key={index}
                  onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    setAnimationCurrent(item);
                    setEditorTab('animation');
                    setAnimationRightClicked({
                      animationId: item.id,
                      x: window.innerWidth - e.pageX < 100 ? e.pageX - 100 : e.pageX,
                      y: window.innerHeight - e.pageY < 70 ? e.pageY - 70 : e.pageY,
                    });
                  }}
                />
              ))}
              {animationRightClicked.animationId && (
                <div
                  className="context-menu context-menu--animation"
                  style={{ top: animationRightClicked.y, left: animationRightClicked.x }}
                >
                  <div
                    className="context-menu__item"
                    onClick={() => {
                      const animationDuplicate = { ...animationCurrent, id: Math.random().toString(36).substr(2, 9) };
                      addAnimation(animationDuplicate);
                      setAnimationRightClicked({
                        animationId: '',
                        x: 0,
                        y: 0,
                      });
                    }}
                  >
                    <Iconify icon="ph:copy" />
                    <span className="context-menu__item-text">Duplicate</span>
                  </div>
                  <div
                    className="context-menu__item"
                    onClick={() => {
                      deleteAnimation(animationCurrent.id);
                      setRemovedSelector(animationCurrent.selector);
                      setAnimationRightClicked({
                        animationId: '',
                        x: 0,
                        y: 0,
                      });
                    }}
                  >
                    <Iconify icon="ph:trash" />
                    <span className="context-menu__item-text">Delete</span>
                  </div>
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
