import React, { useReducer, useCallback, useEffect } from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import NotFound from './components/not-found';
import Editor from './components/Editor/Editor';
import DashboardBarLayout from './components/dashboard-bar-layout';
import AppReducer from './reducers/AppReducer';
import AppContext from './components/AppContext/AppContext';
import Login from './components/Login/Login';
import Projects from './components/Projects/Projects';
import Project from './components/Project/Project';
import Profile from './components/Profile/Profile';
import VerifyCode from './components/VerifyCode/VerifyCode';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import './App.css';

function App() {
  const initialState = { user: null, projects: [] };
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const navigate = useNavigate();
  const checkCurrentUser = useCallback(async () => {
    const token = localStorage.getItem('token');
    const curentUrl = window.location.href;
    if(curentUrl.includes('forgot-password')){
        return;
    }
    if (token === null) {
      navigate('/');
      return;
    }
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/auth/',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios(option);
      if (res.data.data.user) {
        if(res.data.data.user.status === 'pending'){
          navigate('/verify');
        }
        dispatch({ type: 'CURRENT_USER', payload: res.data.data.user });
        dispatch({ type: 'GET_LICENSE', payload: res.data.data.license });
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    checkCurrentUser();
  }, [checkCurrentUser]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('activate') && searchParams.get('url')) {
      localStorage.setItem('redirect', '/projects/?activate=1&url=' + encodeURIComponent(searchParams.get('url') || ''));
    }
  }, [searchParams]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Routes>
        <Route path="/" element={<DashboardBarLayout />}>
          <Route index element={<Login />} />
          <Route path="/forgot-password/" element={<ForgotPassword />} />
          <Route path="/projects/" element={state.user ? <Projects /> : ''} />
          <Route path="/verify/" element={state.user ? <VerifyCode /> : ''} />
          <Route path="/projects/:projectId/" element={state.user ? <Project /> : ''} />
          <Route path="/profile/" element={state.user ? <Profile /> : ''} />
        </Route>
        <Route path="/projects/:projectId/:pageId/:timelineId?/" element={state.user ? <Editor /> : ''} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
