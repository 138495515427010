import React, { useContext, useState } from 'react';
import { TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tooltip } from '@mui/material';
import EditorContext from '../EditorContext/EditorContext';
import Iconify from '../Iconify/Iconify';
import CodeEditor from '@uiw/react-textarea-code-editor';
import './Controls.scss';

function ControlProject(props: any) {
  const { project, setProject } = useContext(EditorContext);

  const handleChange = (e: any, controlType: string) => {
		const controlId = controlType === 'select' ? e.target.name : e.target.attributes?.id?.value;

		switch (controlType) {
			case 'checkbox':
				project[controlId] = !(project[controlId]) ? true : false;
				break;

			case 'number':
				project[controlId] = e.target.valueAsNumber;
				break;

			default:
				project[controlId] = e.target.value;
				break;
		}

		setProject({
			...project,
		});
  };

	const handleChangeProjectSettings = (e: any, controlType: string) => {
		const controlId = controlType === 'select' ? e.target.name : e.target.attributes?.id?.value;
		const newSettings = project.settings ? project.settings : {};

		switch (controlType) {
			case 'checkbox':
				newSettings[controlId] = (project.settings && !project.settings[controlId]) ? true : false;
				break;

			case 'number':
				newSettings[controlId] = e.target.valueAsNumber;
				break;

			default:
				newSettings[controlId] = e.target.value;
				break;
		}

		setProject({
			...project,
			settings: newSettings,
		});
  };

  return (
		<>  
		{project._id && (
		<>
			<div className="controls">
				<div
					className="controls__header"
					onClick={(e) => {
						const tar = e.target as HTMLElement;
						tar.closest('.controls')?.classList.toggle('controls--collapsed');
					}}
				>
					<div className="controls__header-label">Project</div>
					<div className="controls__header-icon">
						<Iconify icon="material-symbols:keyboard-arrow-down" />
					</div>
				</div>

				<div className="controls__body">

					<div className="controls__field-group controls__field-group--label-block">
						<label htmlFor="name">Name</label>
						<TextField
							id="name"
							value={project.name ? project.name : ''}
							fullWidth
							onChange={(e) => {
								handleChange(e, 'text');
							}}
						/>
					</div>

				</div>
			</div>
			
			<div className="controls controls--collapsed">
				<div
					className="controls__header"
					onClick={(e) => {
						const tar = e.target as HTMLElement;
						tar.closest('.controls')?.classList.toggle('controls--collapsed');
					}}
				>
					<div className="controls__header-label">Breakpoints</div>
					<div className="controls__header-icon">
						<Iconify icon="material-symbols:keyboard-arrow-down" />
					</div>
				</div>

				<div className="controls__body">

					<div className="controls__field-group controls__field-group">
						<label htmlFor="breakpointsMobile">Mobile (max-width)</label>
						<TextField
							id="breakpointsMobile"
							type='number'
							value={project.settings?.breakpointsMobile ? project.settings?.breakpointsMobile : 767}
							fullWidth
							onChange={(e) => {
								handleChangeProjectSettings(e, 'number');
							}}
						/>
					</div>

					<div className="controls__field-group controls__field-group">
						<label htmlFor="breakpointsTablet">Tablet (max-width)</label>
						<TextField
							id="breakpointsTablet"
							type='number'
							value={project.settings?.breakpointsTablet ? project.settings?.breakpointsTablet : 991}
							fullWidth
							onChange={(e) => {
								handleChangeProjectSettings(e, 'number');
							}}
						/>
					</div>
					
					<div className="controls__field-group controls__field-group">
						<label htmlFor="breakpointsDesktop">Desktop (min-width)</label>
						<TextField
							id="breakpointsDesktop"
							type='number'
							value={project.settings?.breakpointsTablet ? project.settings?.breakpointsTablet + 1 : 992}
							fullWidth
							onChange={(e) => {
							}}
						/>
					</div>

				</div>
			</div>

			<div className="controls controls--collapsed">
				<div
					className="controls__header"
					onClick={(e) => {
						const tar = e.target as HTMLElement;
						tar.closest('.controls')?.classList.toggle('controls--collapsed');
					}}
				>
					<div className="controls__header-label">Reset timelines</div>
					<div className="controls__header-icon">
						<Iconify icon="material-symbols:keyboard-arrow-down" />
					</div>
				</div>

				<div className="controls__body">

					<div className="controls__field-group">
						<label htmlFor="resetTimelinesOnWindowResize">
							<span>On Window Resize</span>
						</label>
						<Switch
							checked={project.settings?.resetTimelinesOnWindowResize ? true : false}
							onChange={(e) => {
								handleChangeProjectSettings(e, 'checkbox');
							}}
							id='resetTimelinesOnWindowResize'
						/>
					</div>

					<div className="controls__field-group controls__field-group--switch">
						<label htmlFor="resetTimelinesOnURLChangeNotHash">
							<span>On URL Change (not hash)</span>
							<Tooltip title="You need to enable this if your site is a dynamic web application like React, Angular, Vue" arrow placement="top">
                <div className='control-description'><Iconify icon="ph:info" /></div>
              </Tooltip>
						</label>
						<Switch
							checked={project.settings?.resetTimelinesOnURLChangeNotHash ? true : false}
							onChange={(e) => {
								handleChangeProjectSettings(e, 'checkbox');
							}}
							id='resetTimelinesOnURLChangeNotHash'
						/>
					</div>

					<div className="controls__field-group">
						<label htmlFor="resetTimelinesOnHashChange">
							<span>On Hash Change</span>
						</label>
						<Switch
							checked={project.settings?.resetTimelinesOnHashChange ? true : false}
							onChange={(e) => {
								handleChangeProjectSettings(e, 'checkbox');
							}}
							id='resetTimelinesOnHashChange'
						/>
					</div>

				</div>
			</div>

			<div className="controls controls--collapsed">
				<div
					className="controls__header"
					onClick={(e) => {
						const tar = e.target as HTMLElement;
						tar.closest('.controls')?.classList.toggle('controls--collapsed');
					}}
				>
					<div className="controls__header-label">ScrollSmoother</div>
					<div className="controls__header-icon">
						<Iconify icon="material-symbols:keyboard-arrow-down" />
					</div>
				</div>

				<div className="controls__body">

					<div className="controls__field-group">
						<label htmlFor="scrollSmoother">
							<span>Enable</span>
						</label>
						<Switch
							checked={project.settings?.scrollSmoother ? true : false}
							onChange={(e) => {
								handleChangeProjectSettings(e, 'checkbox');
							}}
							id='scrollSmoother'
						/>
					</div>
					
					{project.settings?.scrollSmoother && (
						<>
							<div className="controls__field-group controls__field-group">
								<label htmlFor="scrollSmootherWrapper">Wrapper Selector</label>
								<TextField
									id="scrollSmootherWrapper"
									value={project.settings?.scrollSmootherWrapper ? project.settings?.scrollSmootherWrapper : ''}
									fullWidth
									placeholder="#smooth-wrapper"
									onChange={(e) => {
										handleChangeProjectSettings(e, 'text');
									}}
								/>
							</div>

							<div className="controls__field-group controls__field-group">
								<label htmlFor="scrollSmootherContent">Content Selector</label>
								<TextField
									id="scrollSmootherContent"
									value={project.settings?.scrollSmootherContent ? project.settings?.scrollSmootherContent : ''}
									fullWidth
									placeholder="#smooth-content"
									onChange={(e) => {
										handleChangeProjectSettings(e, 'text');
									}}
								/>
							</div>

							<div className="controls__field-group">
								<label htmlFor="scrollSmootherSmooth">
									Smooth (s)
									<Tooltip title='How long (in seconds) it takes to "catch up" to the native scroll position' arrow placement="top">
										<div className='control-description'><Iconify icon="ph:info" /></div>
									</Tooltip>
								</label>
								<TextField
									id="scrollSmootherSmooth"
									type='number'
									inputProps={{
										step: 0.1,
										lang:"en-US"
									}}
									value={project.settings?.scrollSmootherSmooth ? project.settings?.scrollSmootherSmooth : 0.8}
									fullWidth
									onChange={(e) => {
										handleChangeProjectSettings(e, 'number');
									}}
								/>
							</div>

							<div className="controls__field-group">
								<label htmlFor="scrollSmootherSmoothTouch">
									Smooth Touch (s)
									<Tooltip title='Much shorter smoothing time on touch devices' arrow placement="top">
										<div className='control-description'><Iconify icon="ph:info" /></div>
									</Tooltip>
								</label>
								<TextField
									id="scrollSmootherSmoothTouch"
									type='number'
									inputProps={{
										step: 0.1,
										lang:"en-US"
									}}
									value={project.settings?.scrollSmootherSmoothTouch ? project.settings?.scrollSmootherSmoothTouch : 0}
									fullWidth
									onChange={(e) => {
										handleChangeProjectSettings(e, 'number');
									}}
								/>
							</div>

							<div className="controls__field-group">
								<label htmlFor="scrollSmootherSpeed">
									Speed
									<Tooltip title='2 would make it scroll twice the normal speed, and 0.5 would make it scroll at half-speed' arrow placement="top">
										<div className='control-description'><Iconify icon="ph:info" /></div>
									</Tooltip>
								</label>
								<TextField
									id="scrollSmootherSpeed"
									type='number'
									inputProps={{
										step: 0.1,
										lang:"en-US"
									}}
									value={project.settings?.scrollSmootherSpeed ? project.settings?.scrollSmootherSpeed : ''}
									fullWidth
									onChange={(e) => {
										handleChangeProjectSettings(e, 'number');
									}}
								/>
							</div>

							<div className="controls__field-group">
								<label htmlFor="scrollSmootherEffects">
									<span>Effects</span>
								</label>
								<Switch
									checked={project.settings?.scrollSmootherEffects ? true : false}
									onChange={(e) => {
										handleChangeProjectSettings(e, 'checkbox');
									}}
									id='scrollSmootherEffects'
								/>
							</div>

							<div className="controls__field-group">
								<label htmlFor="scrollSmootherAnchor">
									<span>Smooth Hash Link</span>
								</label>
								<Switch
									checked={project.settings?.scrollSmootherAnchor ? true : false}
									onChange={(e) => {
										handleChangeProjectSettings(e, 'checkbox');
									}}
									id='scrollSmootherAnchor'
								/>
							</div>

							<div className="controls__field-group controls__field-group--label-block">
								<label htmlFor="scrollSmootherCustom">Custom scrollSmoother options</label>
								<div className='code-wrapper'>
									<CodeEditor
										id="scrollSmootherCustom"
										value={project.settings?.scrollSmootherCustom ? project.settings?.scrollSmootherCustom : ''}
										language="js"
										placeholder='property: "value",
property2: "value2",'
										onChange={(e) => {
											handleChangeProjectSettings(e, 'text');
										}}
										data-color-mode="dark"
									/>
								</div>
							</div>

						</>
					)}
				</div>
			</div>

			<div className="controls controls--collapsed">
				<div
					className="controls__header"
					onClick={(e) => {
						const tar = e.target as HTMLElement;
						tar.closest('.controls')?.classList.toggle('controls--collapsed');
					}}
				>
					<div className="controls__header-label">Custom Javascript</div>
					<div className="controls__header-icon">
						<Iconify icon="material-symbols:keyboard-arrow-down" />
					</div>
				</div>

				<div className="controls__body">

					<div className="controls__field-group controls__field-group--label-block">
						<label htmlFor="customJsBefore">Before litemove JS (without script tag)</label>
						<div className='code-wrapper'>
							<CodeEditor
								id="customJsBefore"
								value={project.settings?.customJsBefore ? project.settings?.customJsBefore : ''}
								language="js"
								placeholder='let skewSetter = gsap.quickTo("img", "skewY");'
								onChange={(e) => {
									handleChangeProjectSettings(e, 'text');
								}}
								data-color-mode="dark"
							/>
						</div>
					</div>

					<div className="controls__field-group controls__field-group--label-block">
						<label htmlFor="customJsAfter">After litemove JS (without script tag)</label>
						<div className='code-wrapper'>
							<CodeEditor
								id="customJsAfter"
								value={project.settings?.customJsAfter ? project.settings?.customJsAfter : ''}
								language="js"
								placeholder='gsap.config({ nullTargetWarn: false });'
								onChange={(e) => {
									handleChangeProjectSettings(e, 'text');
								}}
								data-color-mode="dark"
							/>
						</div>
					</div>

				</div>
			</div>
		</>
	)}
	</>
)};

export default ControlProject;
