import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../Iconify/Iconify';

const Register = (props: any) => {
    const [showPassword, setShowPassword] = useState({ password: false, confirm_password: false });

    return (
        <>
            <TextField
                name="first_name"
                type="text"
                label="First Name"
                required
                fullWidth
                autoFocus={true}
                onChange={props.change}
                autoComplete="off"
            />

            <TextField
                name="last_name"
                type="text"
                label="Last Name"
                required
                fullWidth
                onChange={props.change}
                autoComplete="off"
            />

            <TextField
                name="username"
                type="text"
                label="Username"
                required
                fullWidth
                onChange={props.change}
                autoComplete="off"
            />

            <TextField
                name="email"
                type="email"
                label="Email address"
                required
                fullWidth
                value={props.setVal.email}
                onChange={props.change}
                autoComplete="off"
            />

            <TextField
                name="password"
                label="Password"
                type={showPassword.password ? 'text' : 'password'}
                required
                onChange={props.change}
                value={props.setVal.password}
                inputProps={{minLength: 8}}
                error={props.error}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })} edge="end">
                                <Iconify icon={showPassword.password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                autoComplete="off"
            />

            <TextField
                name="confirm_password"
                label="Confirm Password"
                type={showPassword.confirm_password ? 'text' : 'password'}
                required
                error={props.error}
                helperText={props.errorMsg}
                autoFocus={props.error ? true : false}
                onChange={props.change}
                value={props.setVal.confirm_password}
                inputProps={{minLength: 8}}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword({ ...showPassword, confirm_password: !showPassword.confirm_password })} edge="end">
                                <Iconify icon={showPassword.confirm_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                autoComplete="off"
            />
        </>
    );
}

export default Register;