import React, { useState, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import AppContext from '../AppContext/AppContext';
import Modal from '../Modal/Modal';
import FormProject from '../Forms/FormProject';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Iconify from '../Iconify/Iconify';
import { IconButton, Tooltip, Button, Stack } from '@mui/material';

function Projects() {
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [projectDeleteId, setProjectDeleteId] = useState('');
  const { state, dispatch } = useContext(AppContext);
  const { projects } = state;
  const [projectEdited, setProjectEdited] = useState({ _id: '', name: '' });
  const [searchParams] = useSearchParams();
  const [activate, setActivate] = useState({ action: false, url: '', domain: '' });
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('activate') && searchParams.get('url')) {
      const url = decodeURIComponent(searchParams.get('url') || '');
      const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^\/]+)/i);
      const domain = match ? match[1] : '';
      setActivate({ action: true, url, domain });
      setOpenModal(true);
    }
  }, [searchParams]);

  const getAllProjects = useCallback(async () => {
    try {
      const option = {
        method: 'GET',
        url: '/api/v1/projects',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const res = await axios(option);
      if (res.data.data.projects) {
        dispatch({ type: 'GET_ALL_PROJECTS', payload: res.data.data.projects });
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getAllProjects();
  }, [getAllProjects]);

  const updateProject = useCallback(
    debounce(async () => {
      if (projectEdited._id === '') return;
      try {
        const option = {
          method: 'PUT',
          url: '/api/v1/projects/' + projectEdited._id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: projectEdited,
        };
        await axios(option);
      } catch (error) {
        console.log(error);
      }
    }, 300),
    [projectEdited]
  );

  const handleChangeProject = (e: any, id: any, prop: string) => {
    const newProjects = projects.map((project: any) => {
      if (project._id === id) {
        project[prop] = e.target.value;
        setProjectEdited(project);
      }
      return project;
    });
    updateProject();
    dispatch({ type: 'GET_ALL_PROJECTS', payload: newProjects });
  };

  const handleClickProject = (e: any, id: any) => {
    let dataValue = (e.target as HTMLElement).getAttribute('data-key');
    if (dataValue) {
      navigate('/projects/' + id);
    }
  };

  const handleDeleteProject = async (id: any) => {
    try {
      const option = {
        method: 'DELETE',
        url: '/api/v1/projects/' + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      await axios(option);
      const newProjects = projects.filter((project: any) => project._id !== id);
      dispatch({ type: 'GET_ALL_PROJECTS', payload: newProjects });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const projects = state.user ? state.projects : [];
    localStorage.setItem('projects', JSON.stringify(projects));
  }, [state]);

  return (
    <>
      <div className="projects">
        <div
          className="projects__item"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <div className="projects__item-inner">
            + Add new project
          </div>
        </div>
        {projects.map((project: any) => (
          <div
            className="projects__item"
            data-key={project._id}
            key={project._id}
            onClick={(e) => handleClickProject(e, project._id)}
          >
            <div
              className="projects__item-inner"
              onClick={(e) => handleClickProject(e, project._id)}
            >
              <div className="projects__item-name">
                <input type="text" value={project.name} onChange={(e) => handleChangeProject(e, project._id, 'name')} />
              </div>
              <div className="projects__item-tools">
                <div className="projects__item-tool">
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      onClick={() => {
                        setProjectDeleteId(project._id);
                        setOpenModalConfirm(true);
                      }}
                    >
                      <Iconify icon="ph:trash" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="projects__item-url">
              Domain: {project.url.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '')}
            </div>
          </div>
        ))}
      </div>
      {openModal && (
        <Modal
          modalTitle={'Create a new project'}
          setOpenModal={setOpenModal}
          openModal={openModal}
          modalContent={<FormProject openModal={openModal} setOpenModal={setOpenModal} activate={activate}  />}
        />
      )}
      {openModalConfirm && (
        <Modal
          modalTitle={'Confirm to delete this project'}
          setOpenModal={setOpenModalConfirm}
          openModal={openModalConfirm}
          modalContent={
            <div className="modal__content">
              <Stack direction="row" spacing={2} color="#3c6df1">
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    setOpenModalConfirm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => {
                    handleDeleteProject(projectDeleteId);
                    setOpenModalConfirm(false);
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </div>
          }
        />
      )}
    </>
  );
}

export default Projects;
